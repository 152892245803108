/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { faChartLine } from "@fortawesome/pro-regular-svg-icons";
import Colors from "styles/colors";

import { PanelGroup } from "components/molecules/PanelGroup.molecule";

import { convertCountToLocaleString } from "utils/html-utils";
import { FontSize, Text } from "components/atoms/Text.atom";
import { Button } from "components/atoms/Button.atom";
import { Icon } from "components/atoms/Icon.atom";

const metricsDivCss = {
  display: "grid",
  gridTemplateColumns: "repeat(2, 1fr)",
  gap: "1em",
  gridAutoColumns: "minmax(max-content, auto)",
  marginBottom: "1em",
  "> div": {
    padding: "10px 16px",
    borderRadius: "4px",
    backgroundColor: Colors.inventoryView.LIGHTER_GRAY,
  },
};

export const InventoryLocationPanel = ({ location, pushDetailsView }) => {
  const { t } = useTranslation("inventory-view");

  return (
    <Fragment>
      {
        <div key={location.locationId}>
          <PanelGroup>
            <PanelGroup.Header
              title={`(${location.code}) ${location.name}`}
              titleSize={FontSize.size16}
            />
            <PanelGroup.Content>
              <div css={{ ...metricsDivCss }}>
                <div>
                  <Text
                    color={Colors.inventoryView.ORANGE}
                    size={FontSize.size18}
                    bold="true"
                    as="div"
                  >
                    {`${convertCountToLocaleString(
                      940,
                    )}/${convertCountToLocaleString(1000)}`}
                  </Text>
                  <Text size={FontSize.size12} as="div">
                    {t("inventory-view:Current Inventory")}
                  </Text>
                </div>
                <div>
                  <Text
                    color={Colors.inventoryView.RED}
                    size={FontSize.size18}
                    bold="true"
                    as="div"
                  >
                    {`${convertCountToLocaleString(1211)}`}
                  </Text>
                  <Text size={FontSize.size12} as="div">
                    {t("inventory-view:10+ Days on Site")}
                  </Text>
                </div>
              </div>
              <div css={{ ...metricsDivCss }}>
                <div>
                  <Text
                    color={Colors.inventoryView.BLUE}
                    size={FontSize.size18}
                    bold="true"
                    as="div"
                  >
                    {convertCountToLocaleString(1089)}
                  </Text>
                  <Text size={FontSize.size12} as="div">
                    {t("inventory-view:Today's Forecast")}
                  </Text>
                </div>
                <div>
                  <Text
                    color={Colors.inventoryView.GREEN}
                    size={FontSize.size18}
                    bold="true"
                    as="div"
                  >
                    {`${convertCountToLocaleString(
                      1111,
                    )}/${convertCountToLocaleString(1500)}`}
                  </Text>
                  <Text size={FontSize.size12} as="div">
                    {t("inventory-view:Yesterday's Departures")}
                  </Text>
                </div>
              </div>
              <div css={{ textAlign: "center" }}>
                <Button
                  variant="primary"
                  onClick={() => pushDetailsView(location?.locationId)}
                >
                  {t("inventory-view:Expand Metrics")}{" "}
                  <Icon src={faChartLine}></Icon>
                </Button>
              </div>
            </PanelGroup.Content>
          </PanelGroup>
        </div>
      }
    </Fragment>
  );
};

InventoryLocationPanel.propTypes = {
  location: PropTypes.object,
  pushDetailsView: PropTypes.func,
};
