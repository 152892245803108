/** @jsxImportSource @emotion/react */
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useTrackWithMixpanelOnce } from "trackers/mixpanel";

import { useSetTitle } from "components/hooks/useSetTitle";
import { Search } from "components/templates/Search.template";
import { columns } from "./Utilyz.SupplierSearch.columns";
import { Themes } from "components/organisms/base-table/BaseTable.organism";

export const UtilyzSupplierSearch = () => {
  const { t } = useTranslation("utilyz");
  useSetTitle(t("utilyz:Utilyz"));
  useTrackWithMixpanelOnce("Viewed Page: Utilyz / Supplier Search");

  const { data, isFetching } = useQuery({
    throwOnError: true,
    queryKey: [`utilyz-supplier-search`],
    queryFn: () => [],
  });

  return (
    <Search
      isLoading={isFetching}
      searchResults={data}
      totalCount={data?.length ?? 0}
      totalCountIsLoading={isFetching}
      totalCountTitle={t("utilyz:Total Orders")}
      SavedSearchModalContainer={() => null}
      toggleShowFilters={() => null}
      tableProps={{
        hideHeader: true,
        theme: Themes.CARD,
        data: data ?? [],
        columns: columns(t),
        isLoading: isFetching,
      }}
    />
  );
};
