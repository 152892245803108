/** @jsxImportSource @emotion/react */
import { useTranslation } from "react-i18next";
import { FontSize, Text } from "components/atoms/Text.atom";
import { DateTime } from "components/atoms/DateTime.atom";
import { TimeDuration } from "components/atoms/TimeDuration.atom";
import LinkButton from "vendor/signal-widgets/components/link-button";
import { Icon } from "components/atoms/Icon.atom";
import { faPencil } from "@fortawesome/pro-solid-svg-icons";
import { CellProps } from "react-table";
import Colors from "styles/colors";
import { Button } from "components/atoms/Button.atom";

export const PartDetails = (props: CellProps<{}>) => {
  const { origin, pickUpDate, deliverByDate, partName, quantity, isSupplier } =
    props.value;
  const { t } = useTranslation("utilyz");
  return (
    <div
      css={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        gap: "4px",
      }}
    >
      <Text block bold size={FontSize.size16}>
        {isSupplier ? partName : origin}
      </Text>
      <div css={{ display: "flex", flexDirection: "row", gap: "2px" }}>
        <Text color={Colors.text.DIM_GRAY}>{t("utilyz:Pickup By")}:</Text>
        <DateTime
          style={{ color: Colors.text.DIM_GRAY }}
          plain
          dateTime={pickUpDate}
          localize
        />
      </div>
      <div css={{ display: "flex", flexDirection: "row", gap: "2px" }}>
        <Text color={Colors.text.DIM_GRAY}>{t("utilyz:Deliver By")}:</Text>
        <DateTime
          style={{ color: Colors.text.DIM_GRAY }}
          plain
          dateTime={deliverByDate}
          localize
        />
      </div>
      {!isSupplier ? (
        <Text color={Colors.text.DIM_GRAY}>{partName}</Text>
      ) : null}
      <div css={{ display: "flex", flexDirection: "row", gap: "2px" }}>
        <Text color={Colors.text.DIM_GRAY}>{t("utilyz:Quantity")}:</Text>
        <Text color={Colors.text.DIM_GRAY}> {quantity} </Text>
      </div>
    </div>
  );
};

export const OrderDetails = (props: CellProps<{}>) => {
  const {
    orderNumber,
    reasonCode,
    orderDate,
    isDeclined,
    isOrderConfirmed,
    showHideReasonCodeUpdateModal,
    setUpdateDataId,
    setUpdateReasonCode,
    id,
    isSupplier,
  } = props.value;

  const { t } = useTranslation("utilyz");
  return (
    <div
      css={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        flex: 1,
        gap: "3px",
        color: "#282828",
      }}
    >
      <Text block bold size={FontSize.size18}>
        {orderNumber}
      </Text>
      {isDeclined ? null : (
        <div
          css={{
            display: "flex",
            flexDirection: "row",
            gap: ".25em",
            alignItems: "center",
          }}
        >
          {isOrderConfirmed || isSupplier ? null : (
            <LinkButton
              style={{
                fontWeight: 400,
                fontSize: "12px",
                fontStyle: "italic",
                whiteSpace: "nowrap",
              }}
              onClick={(event: React.BaseSyntheticEvent<HTMLButtonElement>) => {
                event.stopPropagation();
                showHideReasonCodeUpdateModal(true);
                setUpdateDataId(id);
                setUpdateReasonCode(
                  reasonCode
                    ? { label: reasonCode.value, value: reasonCode.key }
                    : null,
                );
              }}
            >
              <Icon
                src={faPencil}
                size={FontSize.size14}
                style={{ marginRight: "2px" }}
              />
              <Text color={Colors.text.DIM_GRAY}>{t("utilyz:Edit")}</Text>
            </LinkButton>
          )}
          <Text color={Colors.text.DIM_GRAY}>{t("utilyz:Reason Code")}:</Text>
          <Text color={Colors.text.DIM_GRAY}>{reasonCode?.key ?? ""}</Text>
        </div>
      )}
      <DateTime
        plain
        dateTime={orderDate}
        localize={true}
        bold={false}
        style={{
          color: Colors.text.DIM_GRAY,
        }}
      ></DateTime>
      {isDeclined ? (
        <Button variant="dark">{t("utilyz:Dismiss")}</Button>
      ) : null}
      {isDeclined ? null : (
        <TimeDuration value="2024-08-16T19:00:00Z" humanize={false} />
      )}
    </div>
  );
};
