import {
  getBasicQueryString,
  getEverythingQueryString,
} from "components/search-bar/search-filter-query-strings";

export interface SearchCategory {
  queryKey: string;
  label: (t: Function) => string;
  placeholder: (t: Function) => string;
  queryBuilder: (searchText: string) => string;
  property: string | null;
}

export const SEARCH_CATEGORIES: SearchCategory[] = [
  {
    queryKey: "everything",
    label: (t: Function) => t("utilyz-search:Search Everything"),
    placeholder: (t: Function) => {
      return t("utilyz-search:Search Everything");
    },
    queryBuilder: getEverythingQueryString,
    property: null,
  },
  {
    queryKey: "orderNumber",
    label: (t: Function) => t("utilyz-search:Material Order #"),
    placeholder: (t: Function) => t("utilyz-search:Search Order #"),
    queryBuilder: getBasicQueryString,
    property: "orderNumber",
  },
  {
    queryKey: "partNumber",
    label: (t: Function) => t("utilyz-search:Part #"),
    placeholder: (t: Function) => t("utilyz-search:Search Part #"),
    queryBuilder: getBasicQueryString,
    property: "partNumber",
  },
  {
    queryKey: "supplierName",
    label: (t: Function) => t("utilyz-search:Supplier Name"),
    placeholder: (t: Function) => t("utilyz-search:Search Supplier Names"),
    queryBuilder: getBasicQueryString,
    property: "supplierName",
  },
  {
    queryKey: "supplierLocation",
    label: (t: Function) => t("utilyz-search:Supplier Location"),
    placeholder: (t: Function) => t("utilyz-search:Search Supplier Locations"),
    queryBuilder: getBasicQueryString,
    property: "supplierLocation",
  },
];

export const searchCategorieProps = {
  orderNumber: "",
  partNumber: "",
  supplierName: "",
  supplierLocation: "",
  showSearchOptions: true,
};
