import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import apiUrl from "api-url";

interface OrderFormData {
  items: Array<{
    name: string;
    qty: number;
    reasonCode: string;
    origin: {
      locationCode: string;
      locationId: string;
      locationName: string;
      address: string;
      pickUpDateTime: string;
      notes: string;
      dockNumber: string;
      atDock: boolean;
    };
    destination: {
      locationCode: string;
      locationId: string;
      locationName: string;
      address: string;
      deliveryDateTime: string;
      notes: string;
      dockNumber: string;
      atDock: boolean;
    };
  }>;
}

interface OrderFormRequestVariables {
  formData: OrderFormData;
  id: string;
  isStatusUpdate: boolean;
}

export function useCreateEditOrder() {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: ({
      formData,
      id,
      isStatusUpdate,
    }: OrderFormRequestVariables) => {
      if (id && isStatusUpdate) {
        return axios.patch(`/utilyz/orders/${id}/status-update`, formData);
      } else if (id) {
        return axios.patch(`/utilyz/orders/${id}`, formData);
      }
      return axios.post(apiUrl("/utilyz/orders"), formData);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["utilyz-shipper-search"] });
    },
  });

  return {
    submitForm: mutation.mutate,
    isFormSubmitting: mutation.isPending,
    formError: mutation.error,
    formData: mutation.data,
    clearFormError: () => mutation.reset(),
  };
}
