export const columns = (t: Function) => [
  {
    Header: t("utilyz:Order Number"),
    accessor: "orderNumber",
  },
  {
    Header: t("utilyz:Order Date"),
    accessor: "orderDate",
  },
  {
    Header: t("utilyz:Customer Name"),
    accessor: "customerName",
  },
];
