import { SelectFilterButton } from "components/search-bar/FilterButtons";
import { OrganizationType } from "shared/constants/organization.const";
import { getBasicQueryString } from "components/search-bar/search-filter-query-strings";

export const FILTERS = [
  {
    queryKey: "origin",
    label: (t: Function) => t("Utilyz:Origin"),
    Component: SelectFilterButton,
    optionsGetter: (props: object, t: Function) => {
      return [
        {
          label: t("Utilyz:Destination 1"),
          value: "true",
        },
        {
          label: t("Utilyz:Destination 2"),
          value: "false",
        },
      ];
    },
    component: SelectFilterButton,
    hideFuzzySearch: true,
    hideSelectEmpty: true,
    hideSelectAll: true,
    queryBuilder: getBasicQueryString,
  },
  {
    queryKey: "destination",
    label: (t: Function) => t("Utilyz:Destination"),
    Component: SelectFilterButton,
    optionsGetter: (props: object, t: Function) => {
      return [
        {
          label: t("Utilyz:Destination 1"),
          value: "true",
        },
        {
          label: t("Utilyz:Destination 2"),
          value: "false",
        },
      ];
    },
    component: SelectFilterButton,
    hideFuzzySearch: true,
    hideSelectEmpty: true,
    hideSelectAll: true,
    queryBuilder: getBasicQueryString,
  },
  {
    queryKey: "supplier",
    label: (t: Function) => t("Utilyz:Supplier"),
    Component: SelectFilterButton,
    optionsGetter: (props: object, t: Function) => {
      return [
        {
          label: t("Utilyz:Destination 1"),
          value: "true",
        },
        {
          label: t("Utilyz:Destination 2"),
          value: "false",
        },
      ];
    },
    component: SelectFilterButton,
    hideFuzzySearch: true,
    hideSelectEmpty: true,
    hideSelectAll: true,
    queryBuilder: getBasicQueryString,
    requiredOrgTypes: [OrganizationType.SHIPPER],
  },
  {
    queryKey: "customer",
    label: (t: Function) => t("Utilyz:Customer"),
    Component: SelectFilterButton,
    optionsGetter: (props: object, t: Function) => {
      return [
        {
          label: t("Utilyz:Destination 1"),
          value: "true",
        },
        {
          label: t("Utilyz:Destination 2"),
          value: "false",
        },
      ];
    },
    component: SelectFilterButton,
    hideFuzzySearch: true,
    hideSelectEmpty: true,
    hideSelectAll: true,
    queryBuilder: getBasicQueryString,
    requiredOrgTypes: [OrganizationType.SUPPLIER],
  },
  {
    queryKey: "showArchived",
    label: (t: Function) => t("Utilyz:Show Archived"),
    Component: SelectFilterButton,
    optionsGetter: (props: object, t: Function) => {
      return [
        {
          label: t("Utilyz:True"),
          value: "true",
        },
        {
          label: t("Utilyz:False"),
          value: "false",
        },
      ];
    },
    component: SelectFilterButton,
    hideFuzzySearch: true,
    hideSelectEmpty: true,
    hideSelectAll: true,
    queryBuilder: getBasicQueryString,
  },
];
