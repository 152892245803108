/** @jsxImportSource @emotion/react */
import { CSSProperties } from "react";
import { faClock } from "@fortawesome/pro-solid-svg-icons";
import { Text, FontSize } from "./Text.atom";
import { Icon } from "./Icon.atom";
import Colors from "styles/colors";
import { getDaysHrsMinsBetweenTimestamps } from "utils/date-time";
import { useTranslation } from "react-i18next";

type TimeDurationProps = {
  size?: FontSize;
  style?: CSSProperties;
  value: string;
  humanize?: boolean;
};

export const TimeDuration = (props: TimeDurationProps) => {
  const { duration, formattedDuration } = getDaysHrsMinsBetweenTimestamps(
    new Date().toUTCString(),
    new Date(props.value).toUTCString(),
  );

  const { t } = useTranslation("component");

  const days = duration?.days() ?? 0;
  const diffHours = duration?.hours() ?? 0;
  const diffMinutes = duration?.minutes() ?? 0;

  return (
    <span
      css={{
        ...props.style,
        display: "flex",
        color:
          diffHours > 0 || days > 0
            ? Colors.text.RED
            : diffMinutes > 30
            ? "#f4ad24"
            : Colors.text.LIGHT_GRAY,
        flexDirection: "row",
        gap: ".25em",
        alignItems: "center",
      }}
    >
      <Text size={props.size}>
        {t("component:[[[timeDuration]]] ago", {
          timeDuration: props.humanize
            ? duration?.humanize() ?? "0 min"
            : formattedDuration,
        })}
      </Text>
      <Icon src={faClock}></Icon>
    </span>
  );
};
