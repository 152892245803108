/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { useState } from "react";
import { SelectAsync } from "components/atoms/SelectAsync.atom";
import Colors from "styles/colors";

export const UtiliyzBaseSelectAsync = ({
  placeholder,
  id,
  value,
  setValue,
  formatOptionLabel,
  loadOptions,
  disabled = false,
  isError = false,
}) => {
  const [inputValue, setInputValue] = useState("");

  const customStyles = {
    control: (provided) => ({
      ...provided,
      borderColor: isError ? Colors.text.RED : "hsl(0, 0%, 80%)",
    }),
  };

  return (
    <SelectAsync
      id={id}
      styles={customStyles}
      isDisabled={disabled}
      placeholder={placeholder}
      isMulti={false}
      isSearchable={true}
      closeMenuOnSelect={true}
      menuPosition="fixed"
      onInputChange={(val) => {
        setInputValue(val);
      }}
      libProps={{
        formatOptionLabel: formatOptionLabel,
      }}
      loadOptions={async (currentVal, options, additionalOptions) => {
        return loadOptions(currentVal, additionalOptions, inputValue);
      }}
      debounceTimeout={1000}
      value={value}
      onChange={setValue}
    />
  );
};

UtiliyzBaseSelectAsync.propTypes = {
  placeholder: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.object,
  setValue: PropTypes.func,
  loadOptions: PropTypes.func,
  disabled: PropTypes.bool,
  isError: PropTypes.bool,
};
