import Colors from "styles/colors";

const CardTheme = {
  container: {
    border: "0",
  },
  textColor: Colors.text.DARKER_GRAY,
  header: {
    backgroundColor: "#fff",
    textColor: Colors.text.DARK_BLUE,
    fontWeight: "bold",
    borderBottom: "1px solid rgba(0,0,0,0.1)",
    filters: {
      backgroundColor: Colors.background.LIGHT_GRAY,
      inputBorderColor: "rgba(0, 0, 0, 0.1)",
      inputBackgroundColor: "#fff",
    },
    sorting: {
      selectedColor: Colors.background.DARK_TEAL,
      hover: {
        backgroundColor: "rgba(0, 0, 0, 0.04)",
      },
    },
    resizing: {
      hoverBorderRightColor: "rgba(0, 0, 0, 0.1)",
    },
    boxShadowColor: "rgba(0, 0, 0, 0.1)",
  },
  body: {
    loading: {
      backgroundColor: "rgba(0, 0, 0, 0.6)",
      spinnerColor: "#000",
    },
    row: {
      defaultBackgroundColor: Colors.background.WHITE,
      borderRadius: "3px",
      margin: ".5em",
      lastChildBorderBottom: `1px solid ${Colors.border.GRAY}`,
      errorBackgroundColor: "#faebeb",
      errorBorderColor: "#cf3e37",
      declinedBackgroundColor: "#f7f7f7",
      expanded: {
        borderRadius: "3px 3px 0 0",
        margin: ".5em 0.5em 0 0.5em",
        collapsibleBorderBottom: 0,

        cell: {
          lastCellBorderRadius: 0,
        },
      },
      hover: {
        defaultBackgroundColor: "#fcfcfc",
        errorBackgroundColor: "#faeded",
      },
      clickableHover: {
        backgroundColor: "#fcfcfc",
      },
      highlighted: {
        backgroundColor: Colors.tables.default.highlighted.BACKGROUND,
        alternateBackgroundColor:
          Colors.tables.default.highlighted.ALT_BACKGROUND,
        hover: {
          backgroundColor: Colors.tables.default.highlighted.BACKGROUND_HOVER,
          alternateBackgroundColor:
            Colors.tables.default.highlighted.ALT_BACKGROUND_HOVER,
        },
      },
      disabled: {
        backgroundColor: Colors.tables.default.disabled.BACKGROUND,
      },
      borderBottom: 0,
      borderTopLeft: `1px solid ${Colors.border.GRAY}`,
      lastChildborderRight: `1px solid ${Colors.border.GRAY}`,
    },
    cell: {
      borderRightColor: Colors.border.GRAY,
      errorBorderRightColor: "rgba(207, 62, 55, 0.2)",
      collapsibleBorderRight: 0,
    },
  },
  cell: {
    resizing: {
      borderRightColor: Colors.background.DARK_TEAL,
    },
  },
  pagination: {
    backgroundColor: "#fff",
    textColor: "rgba(0,0,0,0.6)",
    boxShadowColor: "rgba(0, 0, 0, 0.1)",
    borderTopColor: "rgba(0, 0, 0, 0.1)",
    input: {
      borderColor: "rgba(0, 0, 0, 0.1)",
      backgroundColor: "#fff",
      button: {
        textColor: "rgba(0, 0, 0, 0.6)",
        backgroundColor: "rgba(0, 0, 0, 0.1)",
        hoverBackgroundColor: "rgba(0, 0, 0, 0.2)",
      },
    },
  },
};

export default CardTheme;
