/** @jsxImportSource @emotion/react */
import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import apiUrl from "api-url";
import axios from "axios";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { useCreateEditOrder } from "./useCreateOrder";
import { FontSize, Text } from "components/atoms/Text.atom";
import { OriginAndDestinationForm } from "./OriginAndDestinationForm";
import { UtiliyzBaseSelectAsync } from "./UtiliyzBaseSelectAsync";
import { FormControl } from "react-bootstrap";
import { Button } from "components/atoms/Button.atom";
import { Modal } from "components/molecules/Modal.molecule";
import Colors from "styles/colors";

export const CreateEditOrderModal = ({
  locationId,
  onHide,
  show,
  editFormData,
}) => {
  const { submitForm, isFormSubmitting, formError, formData, clearFormError } =
    useCreateEditOrder();

  const { t } = useTranslation("utilyz");
  const [showValidationErrors, setShowValidationErrors] = useState(false);

  const initialLocationData = {
    location: null,
    pickUpDropDate: null,
    pickUpDropLocation: "",
    dockNumber: "",
    atDock: false,
    notes: "",
  };

  const [formState, setFormState] = useState({
    quantity: 0,
    partsValue: null,
    reasonCode: null,
    originData: initialLocationData,
    destinationData: initialLocationData,
  });

  const isLocationDataValid = (data, checkForLocation) =>
    checkForLocation
      ? data.location !== null
      : data.location !== null &&
        data.pickUpDropDate !== null &&
        data.pickUpDropLocation;

  const isValid =
    formState.quantity > 0 &&
    formState.partsValue &&
    formState.reasonCode &&
    isLocationDataValid(formState.originData, true) &&
    isLocationDataValid(formState.destinationData, false);

  useEffect(() => {
    if (editFormData) {
      setFormState({
        ...editFormData,
        originData: editFormData.originData ?? initialLocationData,
        destinationData: editFormData.destinationData ?? initialLocationData,
      });
    }
  }, [editFormData]);

  const formatLocationData = (data) => ({
    locationCode: data.code,
    locationId: data.id,
    locationName: data.name,
    address: data.address,
    pickUpDateTime: data.pickUpDropDate,
    notes: data.notes,
    dockNumber: data.dockNumber,
    atDock: data.atDock,
  });

  const handleSubmit = () => {
    if (isValid) {
      const submitFormData = {
        items: [
          {
            name: formState.partsValue.name,
            qty: formState.quantity,
            reasonCode: formState.reasonCode,
            origin: formatLocationData(formState.originData),
            destination: formatLocationData(formState.destinationData),
          },
        ],
      };
      submitForm({ formData: submitFormData });
    } else {
      setShowValidationErrors(true);
    }
  };

  const updateFormState = (field, value) => {
    setFormState((prevState) => ({ ...prevState, [field]: value }));
  };

  const hideHandler = () => {
    setFormState({
      quantity: 0,
      partsValue: null,
      reasonCode: null,
      originData: initialLocationData,
      destinationData: initialLocationData,
    });
    setShowValidationErrors(false);
    clearFormError();
    onHide();
  };

  const getSearchParams = (searchQuery, pageNumber) => {
    const searchParams = new URLSearchParams();
    searchParams.append("pageNumber", pageNumber?.toString() || "0");
    searchParams.append("pageSize", "20");
    if (searchQuery) {
      searchParams.append("searchText", searchQuery || "");
    }

    return searchParams;
  };

  return (
    <Modal
      show={show}
      size={formError || formData ? "sm" : "xl"}
      centered={formError || formData}
      backdrop="static"
      onHide={hideHandler}
    >
      <Modal.Header
        isLoading={isFormSubmitting}
        closeVariant={"white"}
        css={{
          backgroundColor: "#000000",
          color: "#fff",
        }}
        title={
          isFormSubmitting || formError || formData
            ? t("utilyz:Order Confirmation")
            : !editFormData
            ? t("utilyz:Create Order")
            : t("utilyz:Modify Order")
        }
      />
      <Modal.Body css={{ padding: 0 }}>
        <div css={{ backgroundColor: "#fff" }}>
          {formError ? (
            <div css={{ padding: "1em" }}>
              <Text size={FontSize.size20}>
                {t(
                  "utilyz:We encountered an error while submitting your order. Please try again.",
                )}
              </Text>
            </div>
          ) : formData ? (
            <div css={{ padding: "1em" }}>
              <Text size={FontSize.size20}>
                {t("utilyz:Your order has been successfully submitted.")}
              </Text>
            </div>
          ) : (
            <div
              css={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                css={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  gap: "1em",
                  padding: "1em 1em 0",
                }}
              >
                <div css={{ flexGrow: 12 }}>
                  <Text
                    block
                    bold
                    css={{
                      marginBottom: ".5em",
                      "&:after": {
                        content: '"*"',
                        marginLeft: "2px",
                        color: Colors.text.RED,
                      },
                    }}
                  >
                    {t("utilyz:Part")}
                  </Text>
                  <UtiliyzBaseSelectAsync
                    isError={showValidationErrors && !formState.partsValue}
                    disabled={!_.isEmpty(editFormData) && editFormData !== null}
                    loadOptions={async (
                      currentVal,
                      additionalOptions,
                      searchQuery,
                    ) => {
                      const pageNumber =
                        additionalOptions &&
                        currentVal === additionalOptions?.prevValue
                          ? additionalOptions.pageNumber + 1
                          : 0;
                      const dataUrl = apiUrl(
                        `critical-parts/location-inventory/${locationId}?${getSearchParams(
                          searchQuery,
                          pageNumber,
                        ).toString()}`,
                      );

                      const response = await axios.get(dataUrl).catch((err) => {
                        console.log(err);
                      });

                      const data = response?.data?.data?.map((val) => {
                        return {
                          label: `${val?.label} (${val?.value})`,
                          value: val,
                        };
                      });
                      const metaData = response?.data?.meta;
                      return {
                        options: data ?? [],
                        hasMore: metaData
                          ? metaData.currentPage < metaData.totalPages - 1
                          : false,
                        additional: {
                          pageNumber,
                          prevValue: currentVal,
                        },
                      };
                    }}
                    placeholder={t("utilyz:Select")}
                    id={"parts"}
                    value={formState.partsValue}
                    setValue={(value) => updateFormState("partsValue", value)}
                  />
                  <Text
                    color={Colors.text.RED}
                    css={{
                      visibility:
                        showValidationErrors && !formState.partsValue
                          ? "visible"
                          : "hidden",
                    }}
                  >
                    {t("utilyz:This field is required")}
                  </Text>
                </div>
                <div>
                  <Text
                    block
                    bold
                    css={{
                      marginBottom: ".5em",
                      "&:after": {
                        content: '"*"',
                        marginLeft: "2px",
                        color: Colors.text.RED,
                      },
                    }}
                  >
                    {t("utilyz:Quantity")}
                  </Text>
                  <FormControl
                    type="number"
                    css={{
                      borderColor:
                        showValidationErrors && formState.quantity <= 0
                          ? Colors.text.RED
                          : "#dee2e6",
                    }}
                    min={0}
                    onChange={(e) =>
                      updateFormState("quantity", parseInt(e.target.value))
                    }
                    value={formState.quantity}
                  />
                  <Text
                    color={Colors.text.RED}
                    css={{
                      visibility:
                        showValidationErrors && formState.quantity <= 0
                          ? "visible"
                          : "hidden",
                    }}
                  >
                    {t("utilyz:This field is required")}
                  </Text>
                </div>
                <div css={{ flexGrow: 2 }}>
                  <Text
                    block
                    bold
                    css={{
                      marginBottom: ".5em",
                      "&:after": {
                        content: '"*"',
                        marginLeft: "2px",
                        color: Colors.text.RED,
                      },
                    }}
                  >
                    {t("utilyz:Premium Reason Code")}
                  </Text>
                  <UtiliyzBaseSelectAsync
                    isError={showValidationErrors && !formState.reasonCode}
                    placeholder={t("utilyz:Select a reason code")}
                    loadOptions={async (
                      currentVal,
                      additionalOptions,
                      searchQuery,
                    ) => {
                      const pageNumber =
                        additionalOptions &&
                        currentVal === additionalOptions?.prevValue
                          ? additionalOptions.pageNumber + 1
                          : 0;
                      const dataUrl = apiUrl(
                        `${"utilyz/get-premium-codes"}?${getSearchParams(
                          searchQuery,
                          pageNumber,
                        ).toString()}`,
                      );

                      const response = await axios.get(dataUrl).catch((err) => {
                        console.log(err);
                      });

                      const data = response?.data?.data?.map((val) => {
                        return {
                          label: `${val?.label} (${val?.value})`,
                          value: val?.value,
                        };
                      });
                      const metaData = response?.data?.meta;
                      return {
                        options: data,
                        hasMore: metaData
                          ? metaData.currentPage < metaData.totalPages - 1
                          : false,
                        additional: {
                          pageNumber,
                          prevValue: currentVal,
                        },
                      };
                    }}
                    id={"reasoncode"}
                    value={formState.reasonCode}
                    setValue={(value) => updateFormState("reasonCode", value)}
                  />
                  <Text
                    color={Colors.text.RED}
                    css={{
                      visibility:
                        showValidationErrors && !formState.reasonCode
                          ? "visible"
                          : "hidden",
                    }}
                  >
                    {t("utilyz:This field is required")}
                  </Text>
                </div>
              </div>
              <div
                css={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: ".5em",
                }}
              >
                <div
                  css={{
                    display: "flex",
                    flexDirection: "column",
                    width: "50%",
                    padding: "1em",
                  }}
                >
                  <OriginAndDestinationForm
                    showValidationErrors={showValidationErrors}
                    isEditable={false}
                    isOriginForm={true}
                    data={formState.originData}
                    setData={(value) => updateFormState("originData", value)}
                  />
                </div>
                <div
                  css={{
                    display: "flex",
                    flexDirection: "column",
                    width: "50%",
                    padding: "1em",
                  }}
                >
                  <OriginAndDestinationForm
                    showValidationErrors={showValidationErrors}
                    isEditable={true}
                    isOriginForm={false}
                    data={formState.destinationData}
                    setData={(value) =>
                      updateFormState("destinationData", value)
                    }
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div
          css={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-end",
            gap: "10px",
          }}
        >
          <Button
            variant="secondary"
            onClick={formError ? clearFormError : hideHandler}
          >
            {formError !== null || formData !== null
              ? t("utilyz:Close")
              : t("utilyz:Cancel")}
          </Button>
          {!formData ? (
            <Button variant="success" onClick={handleSubmit}>
              {!formError
                ? editFormData
                  ? t("utilyz:Update Order")
                  : t("utilyz:Submit")
                : t("utilyz:Retry")}
            </Button>
          ) : null}
        </div>
      </Modal.Footer>
    </Modal>
  );
};

CreateEditOrderModal.propTypes = {
  locationId: PropTypes.string,
  onHide: PropTypes.func,
  show: PropTypes.bool,
  editFormData: PropTypes.object,
};
