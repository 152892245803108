import { useCallback } from "react";
import { useTranslation } from "react-i18next";

export enum StateLabels {
  ORDER_SUBMITTED = "Submitted",
  ORDER_CONFIRMED = "Confirmed",
  READY_TO_SHIP = "Ready To Ship",
  SHIPMENT_ASSIGNED = "Shipment Assigned",
  CARRIER_AWAREDED = "Carrier Awarded",
  ORDER_COMPLETED = "Completed",
  ORDER_CANCELLED = "Cancelled",
  ORDER_DECLINED = "Declined",
}

export const useUtilyzTranslations = () => {
  const { t } = useTranslation("utilyz");

  const getStatesLabelTranslation = useCallback(
    (fieldLabel: string) => {
      switch (fieldLabel) {
        case StateLabels.ORDER_SUBMITTED:
          return t("utilyz:Order Submitted");
        case StateLabels.ORDER_CONFIRMED:
          return t("utilyz:Order Confirmed");
        case StateLabels.READY_TO_SHIP:
          return t("utilyz:Ready To Ship");
        case StateLabels.SHIPMENT_ASSIGNED:
          return t("utilyz:Shipment Assigned");
        case StateLabels.ORDER_COMPLETED:
          return t("utilyz:Completed");
        case StateLabels.ORDER_CANCELLED:
          return t("utilyz:Order Cancelled");
        case StateLabels.ORDER_DECLINED:
          return t("utilyz:Order Declined");
        default:
          return fieldLabel;
      }
    },
    [t],
  );

  return {
    getStatesLabelTranslation,
  };
};
