import { getDaysHrsMinsBetweenTimestamps } from "utils/date-time";

export interface OrderState {
  id: string;
  dateTime: string;
  notes: {
    dateTime: string;
    description: string;
  };
  key: string;
  value: string;
  completed: boolean;
}

interface Unit {
  key: string;
  value: string;
}

interface OrderLocation {
  locationId: string;
  locationCode: string;
  locationName: string;
  address: {
    name: string;
    type: {
      key: string;
      value: string;
    };
    line1: string;
    line2: string;
    city: string;
    state: string;
    zip: string;
    country: string;
    latitude: number;
    longitude: number;
  };
  dockNumber: string;
  atDock: boolean;

  notes: {
    description: string;
    dateTime: string;
  };
}

interface OriginLocation extends OrderLocation {
  pickupDateTime: {
    localDateTime: string;
    utcDateTime: string;
    timeZone: string;
  };
}

interface DestinationLocation extends OrderLocation {
  deliveryDateTime: {
    localDateTime: string;
    utcDateTime: string;
    timeZone: string;
  };
}

interface OrderItem {
  id: string;
  name: string;
  qty: number;
  grossWeight: {
    value: string;
    unit: Unit;
  };
  shipQty: string;
  shipUnit: Unit;
  dimensions: {
    length: number;
    width: number;
    height: number;
    unit: Unit;
  };
  reasonCode: {
    id: string;
    key: string;
    value: string;
  };
}

interface Status {
  dateTime: string;
  notes: {
    dateTime: string;
    description: string;
  };
  value: {
    key: string;
    value: string;
  };
}
export interface Order {
  id: string;
  code: string;
  items: OrderItem[];
  states: OrderState[];
  supplier: {
    orgId: string;
    name: string;
  };
  origin: OriginLocation;
  destination: DestinationLocation;
  status: Status[];
}

export const getDeclinedData = (states: OrderState[]): OrderState => {
  const declinedData = states.filter((data: OrderState) => {
    return data.key === "declined" || data.key === "cancelled";
  })[0];

  return declinedData;
};

export const getStateData = (
  states: OrderState[],
  state: string,
): OrderState => {
  const stateData = states.filter((data: OrderState) => {
    return data.key === state;
  })[0];

  return stateData;
};

export const getTimeDifference = (dateTime: string): number => {
  const { duration } = getDaysHrsMinsBetweenTimestamps(
    new Date().toUTCString(),
    new Date(dateTime).toUTCString(),
  );

  return duration?.asMinutes() ?? 0;
};

export const getStateClass = (states: OrderState[]): string => {
  const declinedData = getStateData(states, "declined");
  const cancelledData = getStateData(states, "cancelled");
  const submittedData = getStateData(states, "submitted");
  const confirmedData = getStateData(states, " confirmed");

  const isError =
    !confirmedData?.completed &&
    submittedData?.completed &&
    !declinedData?.completed &&
    !cancelledData?.completed &&
    getTimeDifference(submittedData.dateTime) > 60;

  return isError ? "error" : "";
};
