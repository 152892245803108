/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import apiUrl from "api-url";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { UtiliyzBaseSelectAsync } from "./UtiliyzBaseSelectAsync";
import { Text, FontSize } from "components/atoms/Text.atom";
import { faLocationDot } from "@fortawesome/pro-solid-svg-icons";
import DateTimePicker from "react-widgets/lib/DateTimePicker";
import { Icon } from "components/atoms/Icon.atom";
import { TextInput } from "components/atoms/TextInput.atom";
import {
  getDateFormatFromUserPreferences,
  getTimeFormatFromUserPreferences,
} from "utils/date-time";
import Colors from "styles/colors";

const CustomLabel = ({ isRequired, children }) => {
  return (
    <Text
      size={FontSize.size16}
      block
      css={{
        marginBottom: ".5em",
        "&:after": {
          content: isRequired ? '" *"' : '""',
          color: Colors.text.RED,
        },
      }}
      color={Colors.highlight.DARK_GRAY}
    >
      {children}
    </Text>
  );
};

const ValidationText = ({ show }) => {
  const { t } = useTranslation("utilyz");
  return (
    <Text
      color={Colors.text.RED}
      css={{
        visibility: show ? "visible" : "hidden",
      }}
    >
      {t("utilyz:This field is required")}
    </Text>
  );
};

export const OriginAndDestinationForm = ({
  isOriginForm,
  isEditable,
  data,
  setData,
  showValidationErrors,
}) => {
  const { t } = useTranslation("utilyz");

  const dateFormat = getDateFormatFromUserPreferences();
  const timeFormat = getTimeFormatFromUserPreferences();
  const dateTimeFormat = dateFormat + " " + timeFormat;

  const formatOptionLabel = (data, { context }) => {
    const { label, value } = data;
    if (context === "value") {
      return (
        <div>
          <Text bold>{value.name} - </Text>
          <Text>
            {value.code} {value.address}, {value.city}, {value.state}
          </Text>
        </div>
      );
    }
    return label;
  };

  return (
    <>
      <div
        css={{
          display: "flex",
          flexDirection: "row",
          gap: "10px",
          marginBottom: ".5em",
          alignItems: "center",
        }}
      >
        <Icon
          src={faLocationDot}
          color={Colors.highlight.LIGHT_BLUE}
          size={FontSize.size24}
        />
        <Text size={FontSize.size20} bold block>
          {isOriginForm ? t("utilyz:Origin") : t("utilyz:Destination")}
        </Text>
      </div>
      <div
        css={{
          border: `1px solid ${Colors.border.GRAY}`,
          padding: "1em",
          backgroundColor: "#f0f0f0",
        }}
      >
        <div
          css={{
            display: "flex",
            flexDirection: "column",
            gap: ".5em",
          }}
        >
          <div>
            <CustomLabel isRequired={true}>
              {isOriginForm
                ? t("utilyz:Origin / Pickup")
                : t("utilyz:Destination / DropOff")}
            </CustomLabel>
            <UtiliyzBaseSelectAsync
              isError={showValidationErrors && !data.location && isEditable}
              disabled={!isOriginForm ? !isEditable : false}
              placeholder={
                isOriginForm
                  ? t("utilyz:Select Origin / Pickup")
                  : t("utilyz:Select Destination / DropOff")
              }
              formatOptionLabel={formatOptionLabel}
              loadOptions={async (
                currentVal,
                additionalOptions,
                searchQuery,
              ) => {
                const pageNumber =
                  additionalOptions &&
                  currentVal === additionalOptions?.prevValue
                    ? additionalOptions.pageNumber + 1
                    : 0;
                const url = apiUrl("location/locations");
                const response = await axios
                  .get(url, {
                    params: {
                      name_search: searchQuery ?? "",
                      verbose: false,
                      sortColumn: "name",
                      reverseSort: "0",
                      pageNumber: pageNumber?.toString() ?? "0",
                      pageSize: "20",
                    },
                  })
                  .catch((err) => {
                    console.log(err);
                  });

                const data = response?.data?.data?.map((val) => {
                  return {
                    label: `${val?.name} (${val?.code})`,
                    value: val,
                  };
                });
                const metaData = response?.data?.meta;
                return {
                  options: data,
                  hasMore: metaData
                    ? metaData.currentPage < metaData.totalPages - 1
                    : false,
                  additional: {
                    pageNumber,
                    prevValue: currentVal,
                  },
                };
              }}
              id={isOriginForm ? "pickupLocation" : "dropOffLocation"}
              setValue={(selectedData) => {
                setData({ ...data, location: selectedData });
              }}
              value={data.location}
            />
            <ValidationText show={showValidationErrors && !data.location} />
          </div>
          <div
            css={{
              display: "flex",
              flexDirection: "row",
              gap: ".5em",
              paddingBottom: ".5em",
              alignItems: "flex-start",
            }}
          >
            <div
              css={{
                flexGrow: 2,
              }}
            >
              <CustomLabel isRequired={true}>
                {isOriginForm ? t("utilyz:Pickup By") : t("utilyz:Deliver By")}
              </CustomLabel>
              <DateTimePicker
                css={{
                  ".rw-widget-container": {
                    borderColor:
                      isEditable && showValidationErrors && !data.pickUpDropDate
                        ? Colors.text.RED
                        : "#ccc",
                  },
                }}
                disabled={!isEditable}
                format={dateTimeFormat}
                min={new Date()}
                value={
                  data.pickUpDropDate ? new Date(data.pickUpDropDate) : null
                }
                onChange={(date) => {
                  setData({ ...data, pickUpDropDate: date });
                }}
              />
              <ValidationText
                show={
                  showValidationErrors && !data.pickUpDropDate && isEditable
                }
              />
            </div>
            <div
              css={{
                flexGrow: 1,
              }}
            >
              <CustomLabel isRequired={true}>
                {isOriginForm
                  ? t("utilyz:Pickup Location")
                  : t("utilyz:Delivery Location")}
              </CustomLabel>
              <TextInput
                disabled={!isEditable}
                css={{
                  borderRadius: "0.25rem",
                  border:
                    isEditable &&
                    showValidationErrors &&
                    !data.pickUpDropLocation
                      ? `1px solid ${Colors.text.RED}`
                      : "1px solid #dee2e6",
                }}
                value={data.pickUpDropLocation}
                onChange={(value) => {
                  setData({ ...data, pickUpDropLocation: value });
                }}
              />
              <ValidationText
                show={
                  showValidationErrors && !data.pickUpDropLocation && isEditable
                }
              />
            </div>
          </div>
        </div>
        <div>
          <Text
            size={FontSize.size16}
            block
            css={{ marginBottom: ".5em" }}
            color={Colors.highlight.DARK_GRAY}
          >
            {t("utilyz:Notes")}
          </Text>
          <textarea
            css={{
              resize: "none",
            }}
            className="form-control"
            rows={2}
            value={data.notes}
            onChange={(event) => {
              setData({ ...data, notes: event.target.value });
            }}
            disabled={!isEditable ? true : false}
          ></textarea>
        </div>
      </div>
    </>
  );
};

OriginAndDestinationForm.propTypes = {
  isOriginForm: PropTypes.bool,
  isEditable: PropTypes.bool,
  data: PropTypes.object,
  setData: PropTypes.func,
  showValidationErrors: PropTypes.bool,
};
