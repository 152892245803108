/** @jsxImportSource @emotion/react */
import {
  OrderDetails,
  PartDetails,
} from "../components/Utilyz.column.templates";
import { OrderStatusCell } from "../components/Shipments.OrderStatus";
import { ExpandCell } from "components/organisms/base-table/Cell/TreeCells";
import { faPlus, faMinus } from "@fortawesome/pro-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { Order, OrderState } from "../components/Utilyz.utils";
import { getStateData } from "../components/Utilyz.utils";

const getIsOrderDeclined = (states: OrderState[]) => {
  const declined = getStateData(states ?? [], "declined")?.completed ?? false;
  const cancelled = getStateData(states ?? [], "cancelled")?.completed ?? false;
  return declined || cancelled;
};

export const useColumns = (
  showHideReasonCodeUpdateModal: Function,
  setUpdateDataId: Function,
  setUpdateReasonCode: Function,
) => {
  const { t } = useTranslation("utilyz");
  return [
    {
      Header: t("utilyz:Expander"),
      Cell: ExpandCell,
      accessor: "id",
      width: 50,
      disableResizing: true,
      disableSortBy: true,
      expandedDataCanExpand: "code",
      expandedDataAttribute: "code",
      openIcon: faPlus,
      closeIcon: faMinus,
      isCollapsible: true,
      closeColor: "#fff",
      openColor: "#707070",
    },
    {
      Header: t("utilyz:Order Details"),
      accessor: (d: Order) => ({
        origin: d?.origin?.address?.name ?? [],
        pickUpDate: d?.origin?.pickupDateTime?.utcDateTime ?? "",
        deliverByDate: d?.destination?.deliveryDateTime?.utcDateTime ?? "",
        partName: d?.items[0]?.name ?? "",
        quantity: d?.items[0]?.qty ?? 0,
      }),
      minWidth: 300,
      Cell: PartDetails,
      disableSortBy: true,
      disableResizing: true,
      centerAligned: false,
    },
    {
      Header: t("utilyz:Customer Status"),
      accessor: (d: Order) => ({
        orderDate: d?.status[0]?.dateTime ?? "",
        originAddress: d?.origin?.address?.name ?? "",
        originState: d?.origin?.address?.state ?? "",
        destinationAddress: d?.destination?.address?.name ?? "",
        destinationState: d?.destination?.address?.state ?? "",
        statusItems: d?.states ?? [],
      }),
      minWidth: 690,
      Cell: OrderStatusCell,
      disableSortBy: true,
      disableResizing: true,
      centerAligned: false,
    },
    {
      Header: t("utilyz:Order Id"),
      accessor: (d: Order) => ({
        id: d.id,
        orderNumber: d?.code ?? "",
        reasonCode: d?.items[0]?.reasonCode,
        orderDate: "2024-08-16T19:00:00Z",
        isOrderConfirmed:
          getStateData(d?.states ?? [], "confirmed")?.completed ?? false,
        isDeclined: getIsOrderDeclined(d?.states) ?? false,
        showHideReasonCodeUpdateModal,
        setUpdateDataId,
        setUpdateReasonCode,
      }),
      minWidth: 195,
      Cell: OrderDetails,
      disableSortBy: true,
      disableResizing: true,
      centerAligned: false,
    },
  ];
};
