/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import _ from "lodash";
import moment from "moment";
import { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";

import { useTrackWithMixpanelOnce } from "trackers/mixpanel";
import { MediaQueries } from "components/responsive";
import { useSetTitle } from "components/hooks/useSetTitle";
import { Dashboard } from "components/templates/Dashboard.template";
import { Alert, AlertVariant } from "components/atoms/Alert.atom";
import { ExceptionsPanel } from "components/organisms/ExceptionsPanel.organism";
import SearchBarContainer from "pages/partview/components/search/DealerPartView.SearchBar.container";
import FiltersContainer from "pages/partview/components/search/DealerPartView.SearchFilters.container";
import WatchedPackages from "pages/partview/dashboard/components/organisms/PartView.WatchedPackages.organism";
import { DealerPartViewSavedSearchesPanel } from "pages/partview/dashboard/components/organisms/DealerPartView.SavedSearchesPanel.organism";
import { PartViewEtaWidget } from "pages/partview/dashboard/components/PartViewEtaWidget";
import { getFormattedLifecycleObject } from "pages/partview/utils/lifecycleState.utils";
import { getIconData } from "pages/vinview/utils/exceptions.utils";
import { PackageStatusOption } from "pages/partview/utils/filter.utils";
import Colors from "styles/colors";
import { HalfPieChartAndTable } from "shared/components/organisms/HalfPieChartAndTable.organism";
import { useFormattedExceptionCount } from "pages/partview/utils/exceptions.utils";
import { getExceptionChartColor } from "pages/partview/utils/exceptions.utils";

export const DealerPartViewDashboard = ({
  searchEntities,
  setSearchFilter,
  clearSearchFilters,
  resetSearchBar,
  selectedFvOrgId,
  exceptionOrPackageStatusCountsRequestData = {},
  activeAndExceptionCountsRequestData = {},
  fetchExceptionOrPackageStatusCounts,
  fetchActiveAndExceptionCounts,
  fetchWatchedPackages,
  watchedTableIsLoading,
  watchedTableResults,
  watchedTablePageIndex,
  watchedTablePageSize,
  watchedTablePageCount,
  setWatchedTablePageIndex,
  isDealerOrg,
  etaPackagesCountsRequestData,
  fetchEtaPackagesCounts,
  includeAPU,
  resetSavedSearch,
}) => {
  const { t } = useTranslation("partview-dashboard");
  const [showFilters, setShowFilters] = useState(!isDealerOrg);

  useSetTitle(t("partview-dashboard:PartView"));

  useTrackWithMixpanelOnce("Viewed Page: PartView / Dealer Dashboard");

  const canFetchData =
    isDealerOrg || (!isDealerOrg && !_.isNil(selectedFvOrgId));

  useEffect(() => {
    resetSearchBar();
    clearSearchFilters();
  }, [resetSearchBar, clearSearchFilters]);

  useEffect(() => {
    if (canFetchData) {
      fetchExceptionOrPackageStatusCounts(selectedFvOrgId, true, includeAPU);
      fetchActiveAndExceptionCounts(selectedFvOrgId, includeAPU);
      fetchWatchedPackages(selectedFvOrgId);
      fetchEtaPackagesCounts(selectedFvOrgId);
      resetSavedSearch();
    }
  }, [
    includeAPU,
    canFetchData,
    selectedFvOrgId,
    fetchExceptionOrPackageStatusCounts,
    fetchActiveAndExceptionCounts,
    fetchWatchedPackages,
    fetchEtaPackagesCounts,
    resetSavedSearch,
  ]);

  const formatLifecycleCounts = (
    exceptionOrPackageStatusCountsRequestData = {},
    t,
  ) => {
    let formatLifecycleState = getFormattedLifecycleObject(
      exceptionOrPackageStatusCountsRequestData.data?.data,
      t,
    );
    return formatLifecycleState;
  };
  let packageExceptions = useFormattedExceptionCount(
    activeAndExceptionCountsRequestData?.data?.data?.exceptions,
  );
  const lifecycleStatusObject = formatLifecycleCounts(
    exceptionOrPackageStatusCountsRequestData,
    t,
  );
  const lifecycleStatusAndExceptionLoading =
    exceptionOrPackageStatusCountsRequestData.isLoading;

  const activeAndExceptionCountsLoading =
    activeAndExceptionCountsRequestData.isLoading;

  const getLifecycleStateCount = (lifecycleStatusObject, lifecycleStates) => {
    let count = [];
    let result;
    let lifecycleState;
    lifecycleStates.forEach((type) => {
      lifecycleState = type;
      result = lifecycleStatusObject.filter(
        (type) => type.type.toLowerCase() === lifecycleState,
      );
      if (result.length > 0) {
        count.push(result[0]?.count);
      }
    });
    //total count of created, in_route and available_for_pickup packages
    const totalStateCount =
      count.reduce((totalCount, stateCount) => totalCount + stateCount, 0) ?? 0;
    return totalStateCount;
  };

  const handleCountClick = (lifecycleState) => {
    clearSearchFilters();
    if (lifecycleState === "Active") {
      let activeLifecycleState = [
        PackageStatusOption.CREATED_OR_PACKAGED,
        PackageStatusOption.IN_ROUTE,
        PackageStatusOption.DELAYED,
      ];

      if (includeAPU) {
        activeLifecycleState.push(PackageStatusOption.AVAILABLE_FOR_PICKUP);
      }

      setSearchFilter("lifecycleState", activeLifecycleState);
    }

    if (lifecycleState === "Created") {
      setSearchFilter("lifecycleState", [
        PackageStatusOption.CREATED_OR_PACKAGED,
      ]);
    }

    if (lifecycleState === "InRoute") {
      setSearchFilter("lifecycleState", [PackageStatusOption.IN_ROUTE]);
    }

    if (lifecycleState === "Delayed") {
      setSearchFilter("lifecycleState", [PackageStatusOption.DELAYED]);
    }

    if (lifecycleState === "AvailableForPickUp") {
      setSearchFilter("lifecycleState", [
        PackageStatusOption.AVAILABLE_FOR_PICKUP,
      ]);
    }

    if (lifecycleState === "Delivered") {
      setSearchFilter("lifecycleState", [PackageStatusOption.DELIVERED]);
      setSearchFilter("deliveryDate", {
        from: moment().subtract(24, "hours"), //substract 24 hours from the current date time.
        to: moment(), // current date time
        dateType: ["actual"],
      });
    }

    searchEntities();
  };

  const onEtaCountClick = useCallback(
    (row) => {
      if (row) {
        clearSearchFilters();
        setSearchFilter("lifecycleState", [PackageStatusOption.IN_ROUTE]);
        const date = row.original.date;
        let updatedDate;
        if (date.includes(">")) {
          //date='>{the actual date}', remove the 1st character of the string to get the date
          updatedDate = date.substr(1);
          setSearchFilter("deliveryDate", {
            from: moment(updatedDate).add(24, "hours"),
            to: null,
            dateType: ["eta"],
          });
        } else {
          updatedDate = date;
          setSearchFilter("deliveryDate", {
            from: moment(updatedDate),
            to: moment(updatedDate).add(24, "hours"),
            dateType: ["eta"],
          });
        }

        searchEntities();
      }
    },
    [clearSearchFilters, setSearchFilter, searchEntities],
  );

  const exceptionGroups = [
    {
      title: t("partview-dashboard:Delivered Last 24 hours"),
      includeInDonutChart: false,
      exceptions: [
        {
          name: t("partview-dashboard:Delivered"),
          count: getLifecycleStateCount(lifecycleStatusObject, ["delivered"]),
          icon: {
            ...getIconData("Delivered"),
          },
        },
      ],
      isLoading: lifecycleStatusAndExceptionLoading,
    },
  ];

  const dataForExceptionWidget = (packageExceptions) => {
    return packageExceptions.map((exception) => {
      return {
        ...exception,
        // Generating the full display text here to be used wherever it is needed
        fullDescription: `${exception.type} (${exception.reasonCode})`,
        fill: getExceptionChartColor(exception.reasonCode),
      };
    });
  };

  const handleExceptionRowClick = (row) => {
    if (row) {
      const exceptionType = row?.original?.type;
      const exceptionTypeReasonCode = row?.original?.reasonCode;
      clearSearchFilters();
      setSearchFilter("exception", [
        { label: exceptionType, value: exceptionTypeReasonCode },
      ]);
      searchEntities();
    }
  };

  return (
    <Dashboard
      SearchBarContainer={SearchBarContainer}
      FiltersContainer={FiltersContainer}
      showFilters={showFilters}
      toggleShowFilters={(show) => setShowFilters(show)}
    >
      {!isDealerOrg && _.isNil(selectedFvOrgId) ? (
        <Alert variant={AlertVariant.Warning} show>
          {t(
            "partview-dashboard:Select a Dealer Organization from the filter above to continue.",
          )}
        </Alert>
      ) : null}
      {canFetchData ? (
        <div
          css={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            [MediaQueries.mediumAndUp]: {
              display: "grid",
              gridTemplateColumns: "repeat(6, 1fr)",
            },
          }}
        >
          <div
            css={{
              [MediaQueries.mediumAndUp]: {
                gridColumn: "1 / span 3",
              },
              [MediaQueries.extraLarge]: {
                gridColumn: "1 / span 4",
              },
            }}
          >
            <ExceptionsPanel
              title={t("partview-dashboard:Package Count By Status")}
              exceptionGroups={exceptionGroups}
              handleClickException={() => handleCountClick("Delivered")}
              totalCountChartElement={
                <>
                  <div className="d-flex flex-wrap justify-content-center">
                    <ExceptionsPanel.Chart
                      count={getLifecycleStateCount(lifecycleStatusObject, [
                        "in route",
                        "created",
                        "available for pickup",
                        "delayed",
                      ])}
                      countIsLoading={lifecycleStatusAndExceptionLoading}
                      countLabel={t("partview-dashboard:Active")}
                      onClick={() =>
                        handleCountClick("Active", lifecycleStatusObject)
                      }
                      showNumberSeparator={true}
                    />
                    <ExceptionsPanel.Chart
                      count={getLifecycleStateCount(lifecycleStatusObject, [
                        "created",
                      ])}
                      countIsLoading={lifecycleStatusAndExceptionLoading}
                      countLabel={t("partview-dashboard:Created")}
                      onClick={() =>
                        handleCountClick("Created", lifecycleStatusObject)
                      }
                      fillColor={Colors.donuts.BLUE}
                      showNumberSeparator={true}
                    />
                    <ExceptionsPanel.Chart
                      count={getLifecycleStateCount(lifecycleStatusObject, [
                        "in route",
                      ])}
                      countIsLoading={lifecycleStatusAndExceptionLoading}
                      countLabel={t("partview-dashboard:In Route")}
                      onClick={() =>
                        handleCountClick("InRoute", lifecycleStatusObject)
                      }
                      fillColor={Colors.donuts.PURPLE}
                      showNumberSeparator={true}
                    />
                    {includeAPU ? (
                      <ExceptionsPanel.Chart
                        count={getLifecycleStateCount(lifecycleStatusObject, [
                          "available for pickup",
                        ])}
                        countIsLoading={lifecycleStatusAndExceptionLoading}
                        countLabel={t(
                          "partview-dashboard:Available For Pickup",
                        )}
                        onClick={() => handleCountClick("AvailableForPickUp")}
                        styles={{
                          donutLabelStyle: {
                            fontSize: "13px",
                            width: "5rem",
                            textAlign: "center",
                          },
                        }}
                        fillColor={Colors.donuts.ORANGE}
                        showNumberSeparator={true}
                      />
                    ) : null}
                    <ExceptionsPanel.Chart
                      count={getLifecycleStateCount(lifecycleStatusObject, [
                        "delayed",
                      ])}
                      countIsLoading={lifecycleStatusAndExceptionLoading}
                      countLabel={t("partview-dashboard:Delayed")}
                      onClick={() => handleCountClick("Delayed")}
                      fillColor={Colors.donuts.YELLOW}
                      showNumberSeparator={true}
                    />
                  </div>
                </>
              }
              showNumberSeparator={true}
            />
          </div>
          <div
            css={{
              [MediaQueries.mediumAndUp]: {
                gridColumnStart: 4,
                gridColumnEnd: -1,
                gridRow: "1 / span 2",
              },
              [MediaQueries.extraLarge]: {
                gridColumn: 5,
                gridColumnEnd: -1,
                gridRow: "1 / span 2",
              },
            }}
          >
            <HalfPieChartAndTable
              title={t("partview-dashboard:Active Exceptions Overview")}
              isLoading={activeAndExceptionCountsLoading}
              nameAndCountList={dataForExceptionWidget(packageExceptions)}
              dataLabel={t("partview-dashboard:Exceptions")}
              tableHeaderDataTypeLabel={t("partview-dashboard:Exception Type")}
              tableHeaderDataCountLabel={t("partview-dashboard:Packages")}
              chartDataLabel={t("partview-dashboard:Packages")}
              showTotalCount={false}
              onRowClick={handleExceptionRowClick}
            />
          </div>
          <div
            css={{
              [MediaQueries.mediumAndUp]: {
                gridColumnStart: 1,
                gridColumnEnd: -1,
              },
              [MediaQueries.extraLarge]: {
                gridColumn: "1 / span 4",
                gridRow: "2/ span 3",
              },
            }}
          >
            <PartViewEtaWidget
              isLoading={etaPackagesCountsRequestData?.isLoading}
              data={etaPackagesCountsRequestData?.data?.data?.packages}
              handleEtaClick={onEtaCountClick}
            />
          </div>

          <div
            css={{
              [MediaQueries.mediumAndUp]: {
                gridColumnStart: 1,
                gridColumnEnd: -1,
              },
            }}
          >
            <WatchedPackages
              fetchWatchedPackages={fetchWatchedPackages}
              isLoading={watchedTableIsLoading}
              watchedPackages={watchedTableResults}
              pageIndex={watchedTablePageIndex}
              pageSize={watchedTablePageSize}
              pageCount={watchedTablePageCount}
              setPageIndex={setWatchedTablePageIndex}
              isPartSeller={true}
              selectedFvOrgId={selectedFvOrgId}
              isDealerOrg={isDealerOrg}
              hasDealerView={true}
            />
          </div>
          <div
            css={{
              [MediaQueries.mediumAndUp]: {
                gridColumnStart: 1,
                gridColumnEnd: -1,
              },
            }}
          >
            <DealerPartViewSavedSearchesPanel
              isDealerOrg={isDealerOrg}
              selectedFvOrgId={selectedFvOrgId}
            />
          </div>
        </div>
      ) : null}
    </Dashboard>
  );
};

DealerPartViewDashboard.propTypes = {
  searchEntities: PropTypes.func.isRequired,
  setSearchFilter: PropTypes.func.isRequired,
  clearSearchFilters: PropTypes.func.isRequired,
  resetSearchBar: PropTypes.func.isRequired,
  fetchExceptionOrPackageStatusCounts: PropTypes.func,
  fetchActiveAndExceptionCounts: PropTypes.func,
  fetchWatchedPackages: PropTypes.func.isRequired,
  watchedTableIsLoading: PropTypes.bool,
  watchedTableResults: PropTypes.array,
  watchedTablePageIndex: PropTypes.number,
  watchedTablePageSize: PropTypes.number,
  watchedTablePageCount: PropTypes.number,
  setWatchedTablePageIndex: PropTypes.func.isRequired,
  exceptionOrPackageStatusCountsRequestData: PropTypes.shape({
    data: PropTypes.shape({
      active: PropTypes.number,
      delivered: PropTypes.number,
      exceptions: PropTypes.objectOf(PropTypes.number),
    }),
    isLoading: PropTypes.bool,
  }),
  activeAndExceptionCountsRequestData: PropTypes.shape({
    data: PropTypes.shape({
      active: PropTypes.number,
      delivered: PropTypes.number,
      exceptions: PropTypes.objectOf(PropTypes.number),
    }),
    isLoading: PropTypes.bool,
  }),
  selectedFvOrgId: PropTypes.string,
  isDealerOrg: PropTypes.bool,
  etaPackagesCountsRequestData: PropTypes.object,
  fetchEtaPackagesCounts: PropTypes.func.isRequired,
  includeAPU: PropTypes.bool,
  resetSavedSearch: PropTypes.func.isRequired,
};
