import { FilterSection } from "components/search-bar/FilterSection";
import { getAuthorization } from "modules/auth/AuthorizationSelectors";
import { FILTERS } from "./Utilyz.filterDefs";
import { useSelector } from "react-redux";

interface FilterSectionProps {
  searchEntities: Function;
  clearSearchFilter: (key: string) => null;
  setSearchFilter: (key: string, value: string) => null;
  filterValues: object;
}

export const UtilyzFilterSection = (props: FilterSectionProps) => {
  const auth = useSelector(getAuthorization);
  return (
    <FilterSection
      auth={auth}
      searchFilters={props.filterValues}
      searchEntities={() => {
        props.searchEntities({ ...props.filterValues });
      }}
      clearSearchFilter={props.clearSearchFilter}
      clearSavedSearch={() => null}
      setSearchFilter={props.setSearchFilter}
      filtersMetadata={FILTERS}
      show={true}
      sectionStyle={{ backgroundColor: "#e9ebf2" }}
    />
  );
};
