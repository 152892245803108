/** @jsxImportSource @emotion/react */
import _ from "lodash";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useTrackWithMixpanelOnce } from "trackers/mixpanel";
import { useState } from "react";
import apiUrl from "api-url";
import axios from "axios";

import { Button } from "components/atoms/Button.atom";
import { Icon } from "components/atoms/Icon.atom";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { useSetTitle } from "components/hooks/useSetTitle";
import { Search } from "components/templates/Search.template";
import { useColumns } from "./Utilyz.ShipperSearch.Columns";
import { CreateEditOrderModal } from "../components/organisms/CreateEditOrderModal";
import { ReasonCodeUpdateModal } from "../components/organisms/ReasonCodeUpdateModal";
import { UtilyzSearchBar } from "../components/search/SearchBar";
import { UtilyzFilterSection } from "../components/search/FilterSection";
import { PlantFilter } from "./components/PlantFilter";
import { SEARCH_CATEGORIES } from "../components/search/SearchCategories";
import { Themes } from "components/organisms/base-table/BaseTable.organism";

import { getStateClass } from "../components/Utilyz.utils";
import { SortOrdersByDropdown } from "../components/search/SortOrdersByDropdown";

export const UtilyzShipperSearch = () => {
  const { t } = useTranslation("utilyz");
  useSetTitle(t("utilyz:Utilyz"));
  useTrackWithMixpanelOnce("Viewed Page: Utilyz / Shipper Search");
  const [queryParams, setQueryParamsState] = useState({
    searchFilterType: SEARCH_CATEGORIES[0],
    searchFilterValue: "",
  });

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [show, setShow] = useState(false);
  const [showReasonCodeUpdateModal, setShowReasonCodeUpdateModal] =
    useState(false);
  const [updateReasonCode, setUpdateReasonCode] = useState(null);
  const [updateDataId, setUpdateDataId] = useState(null);
  const [editFormData, setEditFormData] = useState(null);
  const [plant, setPlant] = useState(null);
  const [sortKey, setSortKey] = useState("order_date");

  const [filterParams, setFilterParams] = useState({
    origin: null,
    destination: null,
    customer: null,
    showArchived: null,
  });
  const [internalFilterParams, setInternalFilterParams] = useState({
    origin: null,
    destination: null,
    customer: null,
    showArchived: null,
  });

  const setQueryParams = (filters) => {
    setQueryParamsState((prevParams) => ({
      ...prevParams,
      ...filters,
    }));
  };

  const updateFilterParameters = (data) => {
    setFilterParams(data);
  };

  const showHideHandler = (show) => {
    setShow(show);
    if (editFormData && !show) {
      setEditFormData(null);
    }
  };

  const showHideReasonCodeUpdateModal = (show) => {
    setShowReasonCodeUpdateModal(show);
  };

  const columns = useColumns(
    showHideReasonCodeUpdateModal,
    setUpdateDataId,
    setUpdateReasonCode,
  );

  const setSearchFilter = (key, value) => {
    setInternalFilterParams({ ...internalFilterParams, [key]: value });
  };

  const clearSearchFilter = (key) => {
    setInternalFilterParams({ ...internalFilterParams, [key]: null });
  };

  const { data, isFetching, refetch } = useQuery({
    throwOnError: true,
    queryKey: [
      `utilyz-shipper-search`,
      queryParams.searchFilterType.queryKey,
      queryParams.searchFilterValue,
      filterParams,
      page,
      pageSize,
      sortKey,
    ],
    queryFn: async () => {
      const searchParams = new URLSearchParams();
      searchParams.append("pageNumber", page.toString());
      searchParams.append("pageSize", pageSize.toString());
      Object.keys(filterParams)?.map((key) => {
        if (filterParams[key]) {
          searchParams.append(key, filterParams[key]);
        }
      });
      searchParams.append("sortKey", sortKey);
      searchParams.append(
        queryParams.searchFilterType.queryKey,
        queryParams.searchFilterValue || "",
      );

      const url = apiUrl(`/utilyz/orders?${searchParams.toString()}`);
      const response = await axios.get(url).catch((error) => {
        console.log(error);
      });
      let data = response?.data?.data.map((row) => {
        let isError = getStateClass(row.states) === "error";
        return { ...row, isError: isError };
      });
      if (response?.data?.meta) {
        setTotalPages(response.data.meta.totalPages);
        setTotalCount(response.data.meta.totalCount);
      }
      return data ?? [];
    },
  });

  const refetchSearchResults = () => {
    refetch();
  };

  return (
    <>
      <CreateEditOrderModal
        locationId={plant?.value?.id.toString() ?? ""}
        onHide={() => {
          showHideHandler(false);
        }}
        show={show}
        editFormData={editFormData}
      />
      <ReasonCodeUpdateModal
        show={showReasonCodeUpdateModal}
        onHide={() => {
          showHideReasonCodeUpdateModal(false);
        }}
        reasonCodeValue={updateReasonCode}
        orderId={updateDataId}
      />
      <Search
        css={{
          backgroundColor: "#e9ebf2",
          padding: ".5em",
          alignItems: "flex-end",
        }}
        isLoading={isFetching}
        searchResults={data}
        totalCount={totalCount}
        totalCountIsLoading={isFetching}
        totalCountTitle={t("utilyz:Orders")}
        SavedSearchModalContainer={() => null}
        toggleShowFilters={() => null}
        hideResetSearch={true}
        hideSavedSearch={true}
        SearchBarContainer={() => (
          <UtilyzSearchBar
            searchFilterType={queryParams.searchFilterType}
            searchFilterValue={queryParams.searchFilterValue}
            setSearchFilters={updateFilterParameters}
            filterSelections={internalFilterParams}
            filterParams={filterParams}
            setSearchFilterValues={setQueryParams}
            refetch={refetchSearchResults}
          />
        )}
        FiltersContainer={() => (
          <UtilyzFilterSection
            setSearchFilter={setSearchFilter}
            clearSearchFilter={clearSearchFilter}
            searchEntities={(data) => {
              if (_.isEqual(data, filterParams)) {
                refetch();
              } else {
                updateFilterParameters(data);
              }
            }}
            filterValues={internalFilterParams}
          />
        )}
        postSearchBarContent={
          <Button
            disabled={plant === null}
            variant="success"
            css={{ marginLeft: "0.5em", height: "42px" }}
            onClick={() => {
              setEditFormData(null);
              setShow(true);
            }}
          >
            <Icon src={faPlus} /> {t("Utilyz: Create New Order")}
          </Button>
        }
        preSearchBarContent={
          <div
            css={{
              padding: "0 .5em",
              minWidth: "250px",
            }}
          >
            <PlantFilter plant={plant} setPlant={setPlant} />
          </div>
        }
        customActionContent={
          <SortOrdersByDropdown sortKey={sortKey} setSortKey={setSortKey} />
        }
        tableProps={{
          hideHeader: true,
          theme: Themes.CARD,
          data: data ?? [],
          columns: columns,
          isLoading: isFetching,
          showPagination: true,
          fixPaginationToBottom: true,
          isManualPagination: true,
          pageIndex: page,
          pageSize: pageSize,
          pageCount: totalPages,
          onPageChange: (newPage) => {
            setPage(newPage);
          },
          onPageSizeChange: (newPageSize) => {
            setPage(0);
            setPageSize(newPageSize);
          },
          rowClassHandler: (row) => {
            return getStateClass(row.original.states);
          },
          rowClickHandler: (row) => {
            row.toggleRowExpanded(!row.isExpanded);
          },
          subComponent: (row) => {
            <div>Expanded Content</div>;
          },
        }}
      />
    </>
  );
};
