import Colors from "styles/colors";

const Theme = {
  container: {
    border: "0",
  },
  textColor: Colors.text.DARKER_GRAY,
  header: {
    backgroundColor: "#fff",
    textColor: Colors.text.DARK_BLUE,
    fontWeight: "bold",
    borderBottom: "1px solid rgba(0,0,0,0.1)",
    filters: {
      backgroundColor: Colors.background.MID_GRAY,
      inputBorderColor: "rgba(0, 0, 0, 0.1)",
      inputBackgroundColor: "#fff",
    },
    sorting: {
      selectedColor: Colors.background.DARK_TEAL,
      hover: {
        backgroundColor: "rgba(0, 0, 0, 0.04)",
      },
    },
    resizing: {
      hoverBorderRightColor: "rgba(0, 0, 0, 0.1)",
    },
    boxShadowColor: "rgba(0, 0, 0, 0.1)",
  },
  body: {
    loading: {
      backgroundColor: "rgba(0, 0, 0, 0.6)",
      spinnerColor: "#000",
    },
    row: {
      defaultBackgroundColor: Colors.background.MID_GRAY,
      alternateBackgroundColor: Colors.background.MID_GRAY,
      borderRadius: "0px",
      margin: 0,
      lastChildBorderBottom: 0,
      errorBackgroundColor: "none",
      errorBorderColor: "none",
      declinedBackgroundColor: "none",
      expanded: {
        borderRadius: "0px",
        margin: 0,
        collapsibleBorderBottom: "inherit",

        cell: {
          lastCellBorderRadius: 0,
        },
      },
      hover: {
        defaultBackgroundColor: Colors.background.MID_GRAY_MOUSEOVER,
        alternateBackgroundColor: Colors.background.MID_GRAY_MOUSEOVER,
        errorBackgroundColor: "none",
      },
      clickableHover: {
        backgroundColor: Colors.background.YELLOW_MOUSEOVER,
      },
      highlighted: {
        backgroundColor: Colors.tables.default.highlighted.BACKGROUND,
        alternateBackgroundColor:
          Colors.tables.default.highlighted.ALT_BACKGROUND,
        hover: {
          backgroundColor: Colors.tables.default.highlighted.BACKGROUND_HOVER,
          alternateBackgroundColor:
            Colors.tables.default.highlighted.ALT_BACKGROUND_HOVER,
        },
      },
      disabled: {
        backgroundColor: Colors.tables.default.disabled.BACKGROUND,
        alternateBackgroundColor: Colors.tables.default.disabled.ALT_BACKGROUND,
      },
      borderBottom: "1px solid rgba(0, 0, 0, 0.05)",
      borderTopLeft: 0,
      lastChildborderRight: "none",
    },
    cell: {
      errorBorderRightColor: "none",
      borderRightColor: "rgba(0, 0, 0, 0.02)",
      collapsibleBorderRight: "inherit",
    },
  },
  cell: {
    resizing: {
      borderRightColor: Colors.background.DARK_TEAL,
    },
  },
  pagination: {
    backgroundColor: "#fff",
    textColor: "rgba(0,0,0,0.6)",
    boxShadowColor: "rgba(0, 0, 0, 0.1)",
    borderTopColor: "rgba(0, 0, 0, 0.1)",
    input: {
      borderColor: "rgba(0, 0, 0, 0.1)",
      backgroundColor: "#fff",
      button: {
        textColor: "rgba(0, 0, 0, 0.6)",
        backgroundColor: "rgba(0, 0, 0, 0.1)",
        hoverBackgroundColor: "rgba(0, 0, 0, 0.2)",
      },
    },
  },
};

export default Theme;
