/** @jsxImportSource @emotion/react */
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { CSSObjectWithLabel, StylesConfig } from "react-select";
import { SelectAsync } from "components/atoms/SelectAsync.atom";

type SortOption = {
  label: string;
  value: string;
};

const useSortOptions = (): SortOption[] => {
  const { t } = useTranslation("utilyz");
  return [
    { label: t("utilyz:Order Date"), value: "order_date" },
    {
      label: t("utilyz:Material Order Number"),
      value: "material_order_number",
    },
    { label: t("utilyz:Delivery By Date"), value: "delivery_by_date" },
  ];
};

interface SortOrdersByDropdownProps {
  setSortKey: (sortKey?: string) => void;
  sortKey: string;
}

const NoSeparator = () => null;

export function SortOrdersByDropdown({
  sortKey,
  setSortKey,
}: SortOrdersByDropdownProps) {
  const sortOptions = useSortOptions();
  const { t } = useTranslation("utilyz");
  const [internalValue, setInternalValue] = useState<SortOption | null>(
    sortOptions.find((option) => option.value === sortKey) ?? sortOptions[0],
  );

  const selectLabel: CSSObjectWithLabel = {
    alignItems: "center",
    display: "flex",
    ":before": {
      color: "#ccd2e1",
      content: `"${t("utilyz:Sort by")}"`,
      display: "block",
      marginRight: 8,
      height: 22,
    },
  };

  const styles: StylesConfig = {
    control: (baseStyles) => ({
      ...baseStyles,
      height: "42px",
      margin: "0.5em",
      border: "3px solid #d7dce8",
    }),
    placeholder: (baseStyles) => ({ ...baseStyles, ...selectLabel }),
    singleValue: (baseStyles) => ({ ...baseStyles, ...selectLabel }),
    menu: (baseStyles) => ({
      ...baseStyles,
      zIndex: 9999,
    }),
  };

  return (
    <SelectAsync
      isClearable={false}
      isSearchable={false}
      debounceTimeout={1000}
      isMulti={false}
      options={sortOptions}
      onChange={(selected) => {
        setInternalValue(selected);
        setSortKey(selected?.value);
      }}
      value={internalValue}
      styles={styles}
      components={{ IndicatorSeparator: NoSeparator }}
    />
  );
}
