/** @jsxImportSource @emotion/react */
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import apiUrl from "api-url";
import axios from "axios";
import { Button } from "components/atoms/Button.atom";
import { useTranslation } from "react-i18next";
import { UtiliyzBaseSelectAsync } from "./UtiliyzBaseSelectAsync";
import { useCreateEditOrder } from "./useCreateOrder";
import { Modal } from "components/molecules/Modal.molecule";
import { Text, FontSize } from "components/atoms/Text.atom";

export const ReasonCodeUpdateModal = ({
  orderId,
  reasonCodeValue,
  show,
  onHide,
}) => {
  const { t } = useTranslation("utilyz");
  const [code, setCodeValue] = useState(null);

  useEffect(() => {
    setCodeValue(reasonCodeValue);
  }, [reasonCodeValue]);

  const getSearchParams = (searchQuery, pageNumber) => {
    const searchParams = new URLSearchParams();
    searchParams.append("pageNumber", pageNumber?.toString() || "0");
    searchParams.append("pageSize", "20");
    if (searchQuery) {
      searchParams.append("searchText", searchQuery || "");
    }

    return searchParams;
  };

  const { submitForm, isFormSubmitting, formError, formData, clearFormError } =
    useCreateEditOrder();

  const formatOptionLabel = (data) => {
    const { label, value } = data;

    return (
      <div>
        <Text>
          {value} - {label}
        </Text>
      </div>
    );
  };

  const styles = {
    menu: (baseStyles) => ({
      ...baseStyles,
      zIndex: 9999,
    }),
    control: (provided) => ({
      ...provided,
      height: "2.9em",
      width: "100%",
    }),
  };

  const hideHandler = () => {
    onHide();
    setCodeValue(null);
    clearFormError();
  };

  const handleSubmit = () => {
    submitForm({
      formData: { reasonCode: { key: code.value, value: code.label } },
      id: orderId,
    });
  };

  return (
    <Modal
      show={show}
      centered
      size="md"
      backdrop="static"
      onHide={hideHandler}
    >
      <Modal.Header
        isLoading={isFormSubmitting}
        modalCloseVariant={"white"}
        style={{
          backgroundColor: "#000000",
          color: "#fff",
        }}
        title={t("utilyz:Update Order")}
      />
      <Modal.Body css={{ padding: 0 }}>
        {formError ? (
          <div css={{ padding: "1em" }}>
            <Text size={FontSize.size20}>
              {t(
                "utilyz:We encountered an error while Updating your order. Please try again.",
              )}
            </Text>
          </div>
        ) : formData ? (
          <div css={{ padding: "1em" }}>
            <Text size={FontSize.size20}>
              {t("utilyz:Your order has been successfully updated.")}
            </Text>
          </div>
        ) : (
          <div css={{ backgroundColor: "#fff", padding: "1em" }}>
            <Text
              size={FontSize.size14}
              block
              css={{ marginBottom: ".25em" }}
              align="center"
            >
              {t("utilyz:Please Select Updated Code")}
            </Text>

            <Text
              size={FontSize.size14}
              block
              css={{ marginBottom: ".25em" }}
              align="center"
            >
              {t("utilyz:Current Code")}:
            </Text>

            <Text
              size={FontSize.size14}
              block
              bold
              css={{ marginBottom: ".25em" }}
              align="center"
            >
              {code?.value} - {code?.label}
            </Text>

            {/* display the current code here */}

            <Text size={FontSize.size14} block css={{ marginBottom: ".25em" }}>
              {t("utilyz:Updated Code")}
            </Text>

            <UtiliyzBaseSelectAsync
              styles={styles}
              placeholder={t("utilyz:Select a reason code")}
              formatOptionLabel={formatOptionLabel}
              loadOptions={async (
                currentVal,
                additionalOptions,
                searchQuery,
              ) => {
                const pageNumber =
                  additionalOptions &&
                  currentVal === additionalOptions?.prevValue
                    ? additionalOptions.pageNumber + 1
                    : 0;
                const dataUrl = apiUrl(
                  `${"utilyz/get-premium-codes"}?${getSearchParams(
                    searchQuery,
                    pageNumber,
                  ).toString()}`,
                );

                const response = await axios.get(dataUrl).catch((err) => {
                  console.log(err);
                });

                const data = response?.data?.data?.map((val) => {
                  return {
                    label: `${val?.label}`,
                    value: val?.value,
                  };
                });
                const metaData = response?.data?.meta;
                return {
                  options: data,
                  hasMore: metaData
                    ? metaData.currentPage < metaData.totalPages - 1
                    : false,
                  additional: {
                    pageNumber,
                    prevValue: currentVal,
                  },
                };
              }}
              id={"reasoncode-update"}
              value={code}
              setValue={(value) => setCodeValue(value)}
            />
          </div>
        )}
      </Modal.Body>
      <Modal.Footer style={{ backgroundColor: "#fff" }}>
        <div
          css={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            gap: "1em",
            flex: 1,
          }}
        >
          <Button
            css={{
              flex: 1,
            }}
            variant="secondary"
            onClick={hideHandler}
          >
            {formData ? t("utilyz:Close") : t("utilyz:Cancel")}
          </Button>
          {!formData ? (
            <Button
              css={{
                flex: 1,
              }}
              variant="primary"
              onClick={handleSubmit}
            >
              {formError ? t("utilyz:Retry") : t("utilyz:Update Code")}
            </Button>
          ) : null}
        </div>
      </Modal.Footer>
    </Modal>
  );
};

ReasonCodeUpdateModal.propTypes = {
  orderId: PropTypes.string,
  reasonCodeValue: PropTypes.object,
  show: PropTypes.bool,
  onHide: PropTypes.func,
};
