/** @jsxImportSource @emotion/react */
import { useTranslation } from "react-i18next";
import { Text } from "components/atoms/Text.atom";
import { faCircle, faClock } from "@fortawesome/pro-solid-svg-icons";
import { Radio } from "components/atoms/Radio.atom";
import { Icon } from "components/atoms/Icon.atom";
import { CellProps } from "react-table";

import { useUtilyzTranslations } from "../useUtilyzTranslations";

interface OrderStatus {
  value: string;
  key: string;
  completed: boolean;
  id: string;
  dataTime: string;
  notes: {
    description: string;
    dateTime: string;
  };
}

export const OrderStatusCell = (
  props: CellProps<
    {},
    {
      orderDate: string;
      originAddress: string;
      originState: string;
      destinationAddress: string;
      destinationState: string;
      statusItems: OrderStatus[];
    }
  >,
) => {
  const {
    orderDate,
    originAddress,
    originState,
    destinationAddress,
    destinationState,
    statusItems,
  } = props.value;
  const { t } = useTranslation("utilyz");

  const { getStatesLabelTranslation } = useUtilyzTranslations();
  const declinedData =
    statusItems?.filter((status) => {
      return status.key === "declined" || status.key === "cancelled";
    })[0] ?? [];
  const isDeclined = declinedData
    ? declinedData.key === "declined" && declinedData.completed
    : false;
  const isCancelled = declinedData
    ? declinedData.key === "cancelled" && declinedData.completed
    : false;
  const isGreaterThanTwoHours =
    new Date().getTime() - new Date(orderDate).getTime() > 2 * 60 * 60 * 1000;
  return (
    <div
      css={{
        display: "flex",
        flexDirection: "row",
        flex: 1,
        gap: "1em",
        justifyContent: "center",
        alignItems: "flex-start",
      }}
    >
      {statusItems?.map((statusItem, index: number) => {
        const { key, value } = statusItem;
        return key === "submitted" ||
          key === "cancelled" ||
          key === "declined" ? null : (
          <div
            key={index}
            css={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "4px",
            }}
          >
            <Radio
              css={{ justifyContent: "center" }}
              checkedColor={
                (isDeclined || isCancelled) && value === "Confirmed"
                  ? "#cf3e37"
                  : "#3DAD2A"
              }
              checkedIcon={faCircle}
              checked={
                value === "Confirmed"
                  ? isDeclined || isCancelled
                    ? true
                    : statusItem.completed
                  : statusItem.completed
              }
            />
            <Text
              align={"center"}
              block
              bold={statusItem.completed}
              color={
                (isDeclined || isCancelled) && value === "Confirmed"
                  ? "#cf3e37"
                  : "#282828"
              }
            >
              {(isDeclined || isCancelled) && value === "Confirmed"
                ? getStatesLabelTranslation(
                    isCancelled ? "Cancelled" : "Declined",
                  )
                : getStatesLabelTranslation(value)}
            </Text>
            {key === "confirmed" ||
            key === "cancelled" ||
            key === "declined" ||
            key === "completed" ? (
              <div
                css={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignContent: "center",
                  gap: "3px",
                  opacity: "60%",
                }}
              >
                <Text block align="center" bold color="#282828">
                  {key === "completed" ? destinationAddress : originAddress}
                </Text>
                <Text block align="center" color="#282828">
                  {key === "completed" ? destinationState : originState}
                </Text>
              </div>
            ) : null}
            {key === "carrierAwarded" && isGreaterThanTwoHours ? (
              <div
                css={{
                  display: "flex",
                  flexDirection: "row",
                  color: "#f4ad24",
                  gap: "5px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Icon src={faClock} />
                <Text align="center">
                  {t("utilyz:Under 2 hours remaining")}
                </Text>
              </div>
            ) : null}
          </div>
        );
      })}
    </div>
  );
};
