/** @jsxImportSource @emotion/react */
import { useTranslation } from "react-i18next";
import { StylesConfig } from "react-select";
import { useSelector } from "react-redux";
import { useQueryClient } from "@tanstack/react-query";

import { getActiveOrganization } from "modules/organizations/OrganizationsState";
import LadsState from "shared/redux/Lads.state";
import { SelectAsync } from "components/atoms/SelectAsync.atom";
import {
  ActiveOrg,
  getPlants,
  Lad,
  PlantLocation,
} from "./PlantFilter.service";
import { Lads } from "shared/utils/lads.utils";
import { FontSize, Text } from "components/atoms/Text.atom";

interface PlantFilterProps {
  setPlant: (plant: any) => void;
  plant: {
    label: string;
    value: PlantLocation;
  };
}

const styles: StylesConfig = {
  menu: (baseStyles) => ({
    ...baseStyles,
    zIndex: 9999,
  }),
  control: (provided) => ({
    ...provided,
    height: "2.9em",
  }),
};

export function PlantFilter({ plant, setPlant }: PlantFilterProps) {
  const { t } = useTranslation("utilyz");
  const queryClient = useQueryClient();

  const activeOrg: ActiveOrg = useSelector(getActiveOrganization);
  const activeOrgId = activeOrg.organization_id;

  const lads: Lad[] = useSelector(LadsState.selectors.getLadsList).filter(
    (lad: Lad) => lad.default_name === Lads.CUSTOMER_PLANT,
  );

  return (
    <>
      <Text
        size={FontSize.size16}
        color="#252525"
        block
        bold
        css={{
          marginBottom: ".25em",
        }}
      >
        {t("utilyz:My Location")}
      </Text>
      <SelectAsync
        styles={styles}
        isClearable={true}
        isSearchable={true}
        debounceTimeout={1000}
        loadOptions={async (name_search: string, _loadedOptions, page) =>
          getPlants({
            queryClient,
            activeOrgId,
            lads,
            name_search,
            page,
          })
        }
        onChange={(selected) => setPlant(selected)}
        value={plant}
      />
    </>
  );
}
