/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import Colors from "styles/colors";
import { Modal as BootstrapModal } from "react-bootstrap";
import React from "react";
import Loader from "react-loader";

export const Modal = (props) => {
  return <BootstrapModal {...props} />;
};

Modal.propTypes = {
  show: PropTypes.bool,
  "data-qa": PropTypes.string,
};

const ModalHeader = ({ title, closeVariant, isLoading = false, className }) => {
  return (
    <BootstrapModal.Header
      closeButton
      closeVariant={closeVariant}
      css={{
        backgroundColor: Colors.background.LIGHT_GRAY,
        color: Colors.background.DARK_BLUE,
        padding: "0.5rem 0.75rem 0.5rem 0.5rem",
      }}
      className={className}
    >
      <BootstrapModal.Title style={{ fontWeight: "300" }}>
        {title}
        <span
          css={{
            position: "relative",
            ".loadedContent": { display: "inline" },
          }}
        >
          <Loader
            style={{ display: "inline-block" }}
            loaded={!isLoading}
            left="1em"
            scale={0.5}
          />
        </span>
      </BootstrapModal.Title>
    </BootstrapModal.Header>
  );
};

ModalHeader.propTypes = {
  title: PropTypes.node.isRequired,
  closeVariant: PropTypes.string,
  isLoading: PropTypes.bool,
};
Modal.Header = ModalHeader;
Modal.Header.displayName = "Modal.Header";

const ModalBody = React.forwardRef(
  ({ style = {}, className, children }, modalBodyRef) => {
    return (
      <BootstrapModal.Body
        ref={modalBodyRef}
        style={{ backgroundColor: Colors.background.LIGHT_GRAY, ...style }}
        className={className}
      >
        {children}
      </BootstrapModal.Body>
    );
  },
);

ModalBody.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
};
Modal.Body = ModalBody;
Modal.Body.displayName = "Modal.Body";

const ModalFooter = ({ children, style = {} }) => {
  return (
    <BootstrapModal.Footer style={{ backgroundColor: "#e2e2e2", ...style }}>
      {children}
    </BootstrapModal.Footer>
  );
};

ModalFooter.propTypes = {
  children: PropTypes.node.isRequired,
  style: PropTypes.object,
};
Modal.Footer = ModalFooter;
Modal.Footer.displayName = "Modal.Footer";
