import Colors from "styles/colors";

const Theme = {
  container: {
    border: "1px solid rgba(0, 0, 0, 0.1)",
  },
  textColor: "#fff",
  header: {
    backgroundColor: Colors.background.DARK_GRAY,
    textColor: Colors.tableHeadingText,
    fontWeight: "normal",
    borderBottom: "0",
    filters: {
      backgroundColor: Colors.background.DARK_HIGHLIGHT_GRAY,
      inputBorderColor: "rgba(0, 0, 0, 0.1)",
      inputBackgroundColor: "#fff",
    },
    sorting: {
      selectedColor: Colors.background.DARK_TEAL,
      hover: {
        backgroundColor: Colors.background.DARK_MOUSEOVER_GRAY,
      },
    },
    resizing: {
      hoverBorderRightColor: Colors.background.DARK_HIGHLIGHT_GRAY,
    },
    boxShadowColor: "rgba(0, 0, 0, 0.1)",
  },
  body: {
    loading: {
      backgroundColor: "rgba(0, 0, 0, 0.6)",
      spinnerColor: "#000",
    },
    row: {
      defaultBackgroundColor: Colors.background.DARK_HIGHLIGHT_GRAY,
      alternateBackgroundColor: Colors.background.DARK_GRAY,
      borderRadius: "0px",
      margin: 0,
      lastChildBorderBottom: 0,
      errorBackgroundColor: "none",
      errorBorderColor: "none",
      declinedBackgroundColor: "none",
      expanded: {
        borderRadius: "0px",
        margin: 0,
        collapsibleBorderBottom: "inherit",

        cell: {
          lastCellBorderRadius: 0,
        },
      },
      hover: {
        defaultBackgroundColor: Colors.background.DARK_MOUSEOVER_GRAY,
        alternateBackgroundColor: Colors.background.DARK_GRAY,
        errorBackgroundColor: "none",
      },
      clickableHover: {
        backgroundColor: Colors.background.DARK_YELLOW_MOUSEOVER,
      },
      highlighted: {
        backgroundColor: Colors.tables.dark.highlighted.BACKGROUND,
        alternateBackgroundColor: Colors.tables.dark.highlighted.ALT_BACKGROUND,
        hover: {
          backgroundColor: Colors.tables.dark.highlighted.BACKGROUND_HOVER,
          alternateBackgroundColor:
            Colors.tables.dark.highlighted.ALT_BACKGROUND_HOVER,
        },
      },
      disabled: {
        backgroundColor: Colors.tables.dark.disabled.BACKGROUND,
        alternateBackgroundColor: Colors.tables.dark.disabled.ALT_BACKGROUND,
      },
      borderBottom: "1px solid rgba(0, 0, 0, 0.05)",
      borderTopLeft: 0,
      lastChildborderRight: "none",
    },
    cell: {
      borderRightColor: "rgba(0, 0, 0, 0.02)",
      errorBorderRightColor: "none",
      collapsibleBorderRight: "inherit",
    },
  },
  cell: {
    resizing: {
      borderRightColor: Colors.background.DARK_TEAL,
    },
  },
  pagination: {
    backgroundColor: Colors.background.DARK_GRAY,
    textColor: "#fff",
    boxShadowColor: "rgba(0, 0, 0, 0.1)",
    borderTopColor: "rgba(0, 0, 0, 0.1)",
    input: {
      borderColor: "rgba(0, 0, 0, 0.1)",
      backgroundColor: "#fff",
      button: {
        textColor: "#fff",
        backgroundColor: "rgba(0, 0, 0, 0.1)",
        hoverBackgroundColor: "rgba(0, 0, 0, 0.2)",
      },
    },
  },
};

export default Theme;
