/** @jsxImportSource @emotion/react */
import * as _ from "lodash";
import { Interpolation, Theme } from "@emotion/react";
import { CellValue } from "react-table";
import { FontSize, Icon } from "components/atoms/Icon.atom";
import treeStartImage from "assets/tree-start.svg";
import treeMiddleImage from "assets/tree-middle.svg";
import treeEndImage from "assets/tree-end.svg";
import Colors from "styles/colors";

const treeImageStyle: Interpolation<Theme> = {
  position: "absolute",
  left: "50%",
  top: "50%",
  transform: "translate(0%, -50%)",
  pointerEvents: "none",
};

export const TreeStartCell = (cell: CellValue) => {
  if (!cell || !cell.row) {
    return null;
  }
  const isExpanded = cell.row.isExpanded ?? false;
  const { expandedDataCanExpand } = cell.row.cells[0].column;
  const { openIcon, closedIcon } = cell.row.cells[0].column;
  const { openColor, closedColor } = cell.row.cells[0].column;
  const canExpand = _.get(cell.row.original, expandedDataCanExpand);

  const onClick = () =>
    canExpand ? cell.row.toggleRowExpanded(!isExpanded) : null;

  if (isExpanded) {
    return (
      <div
        onClick={onClick}
        className="mx-auto my-0"
        css={{ cursor: "pointer" }}
      >
        <Icon
          src={closedIcon}
          size={FontSize.size12}
          css={{ color: closedColor ?? Colors.highlight.RED }}
        />
        <img
          key="tree-start"
          src={treeStartImage}
          alt="Tree Start"
          css={{
            ...treeImageStyle,
            marginTop: 10, // to prevent overlap with icon
          }}
        />
      </div>
    );
  }
  return (
    <div onClick={onClick} className="mx-auto my-0" css={{ cursor: "pointer" }}>
      {canExpand ? (
        <Icon
          src={openIcon}
          size={FontSize.size12}
          css={{ color: openColor ?? Colors.highlight.GREEN }}
        />
      ) : null}
    </div>
  );
};

export const TreeMiddleAndEndCell = (cell: CellValue) => {
  const isLastRow = cell.row.index === cell.rows.length - 1;
  if (isLastRow) {
    return (
      <img
        key="tree-end"
        src={treeEndImage}
        alt="Tree End"
        css={treeImageStyle}
      />
    );
  }
  return (
    <img
      key="tree-middle"
      src={treeMiddleImage}
      alt="Tree Middle"
      css={treeImageStyle}
    />
  );
};

export const ExpandCell = (cell: CellValue) => {
  if (!cell || !cell.row) {
    return null;
  }
  const isExpanded = cell.row.isExpanded ?? false;
  let isError = cell.row.original.isError ?? false;
  const { expandedDataCanExpand } = cell.row.cells[0].column;
  const { openIcon, closeIcon } = cell.row.cells[0].column;
  const { openColor, closeColor } = cell.row.cells[0].column;
  const canExpand = _.get(cell.row.original, expandedDataCanExpand);

  let iconColor = openColor;
  if (isExpanded) {
    iconColor = closeColor;
  } else if (isError) {
    iconColor = "#fff";
  }

  const onClick = () =>
    canExpand ? cell.row.toggleRowExpanded(!isExpanded) : null;

  return (
    <div
      onClick={onClick}
      css={{
        cursor: "pointer",
        backgroundColor: isError ? "#cf3e37" : "#e9ebf2",
        display: "flex",
        height: "100%",
        justifyContent: "center",
        paddingTop: "2em",
        width: "100%",
      }}
    >
      <Icon
        src={isExpanded ? closeIcon : openIcon}
        size={FontSize.size12}
        css={{ color: iconColor }}
      />
    </div>
  );
};
