/** @jsxImportSource @emotion/react */
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { SearchBar } from "components/search-bar/SearchBar";
import {
  SEARCH_CATEGORIES,
  searchCategorieProps,
  SearchCategory,
} from "./SearchCategories";

interface SearchFilterValues {
  searchFilterType?: SearchCategory;
  searchFilterValue?: string;
}

interface FilterSelection {
  origin?: string;
  destination?: string;
  customer?: string;
  supplier?: string;
  showArchived?: string;
}

interface SearchBarProps {
  setSearchFilterValues: (values: SearchFilterValues) => void;
  setSearchFilters: (values: FilterSelection) => void;
  searchFilterType: SearchCategory;
  searchFilterValue: string;
  filterSelections: FilterSelection;
  filterParams: FilterSelection;
  refetch: Function;
}

export const UtilyzSearchBar = (props: SearchBarProps) => {
  const { t } = useTranslation("utilyz");
  const [hasSearchTextChanged, setHasSearchTextChanged] = useState(false);

  const [internalSearchText, setInternalSearchText] = useState(
    props.searchFilterValue ?? "",
  );

  const [internalSearchCategory, setInternalSearchCategory] = useState(
    props.searchFilterType,
  );

  return (
    <SearchBar
      t={t}
      canUserSearch={true}
      isShowingFilters={false}
      {...searchCategorieProps}
      clearSavedSearch={() => null}
      isShowingAdvancedSearchButton={false}
      typeaheadOptionsMetadata={SEARCH_CATEGORIES}
      hasSearchCriteriaChanged={hasSearchTextChanged}
      searchText={internalSearchText}
      setSearchText={(val: string) => {
        setInternalSearchText(val);
        setHasSearchTextChanged(true);
      }}
      searchCategory={internalSearchCategory}
      setSearchCategory={(cat: string) => {
        setInternalSearchCategory(
          SEARCH_CATEGORIES.find((c) => c.queryKey === cat)!,
        );
        setHasSearchTextChanged(true);
      }}
      searchEntities={() => {
        if (
          internalSearchText !== props.searchFilterValue ||
          internalSearchCategory !== props.searchFilterType ||
          !_.isEqual(props.filterParams, props.filterSelections)
        ) {
          props.setSearchFilters(props.filterSelections);
          props.setSearchFilterValues({
            searchFilterValue: internalSearchText,
            searchFilterType: internalSearchCategory,
          });
        } else {
          props.refetch();
        }
        setHasSearchTextChanged(false);
      }}
      resetSearchBar={() =>
        props.setSearchFilterValues({
          searchFilterValue: "",
        })
      }
    />
  );
};
