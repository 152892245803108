import axios from "axios";
import { QueryClient } from "@tanstack/react-query";

import apiUrl from "api-url";

const ONE_HOUR_IN_MILLISECONDS = 1000 * 60 * 60;

export interface PlantLocation {
  child_count: number;
  id: number;
  organization_id: number;
  customer_id: number;
  name: string;
  code: string;
  is_active: boolean;
  category: string;
  address: string;
  address2: string;
  city: string;
  state: string;
  country: string;
  postal_code: string;
  region: string[];
  time_zone: string;
  verified: boolean;
  geofence: {
    type: string;
    geometry: {
      type: string;
      coordinates: [number, number];
    };
    properties: {
      buffer: number;
      center: {
        latitude: number;
        longitude: number;
      };
      firstCenter: {
        latitude: number;
        longitude: number;
      };
    };
    lastUpdater: string;
    lastUpdateTime: string;
  };
  lad: {
    id: string;
    name: string;
    canonical_name: string;
    code: string;
    description: string;
    lob_name: string;
  };
  referent_id: number | null;
  parent_location_code: string | null;
  resolved_referent_id: number;
  count: number;
}

export interface ActiveOrg {
  organization_id: number;
}

export interface Lad {
  id: number;
  default_name: string;
}

interface GetPlantArgs {
  queryClient: QueryClient;
  activeOrgId: number;
  lads: Lad[];
  name_search?: string;
  page?: number;
}

export async function getPlants({
  queryClient,
  activeOrgId,
  lads,
  name_search,
  page,
}: GetPlantArgs) {
  const searchParams = new URLSearchParams();

  searchParams.append("verbose", "false");
  searchParams.append(
    "organization_lad_id",
    JSON.stringify(lads.map((lad) => lad.id)),
  );
  searchParams.append("name_search", name_search || "");
  searchParams.append("pageNumber", page?.toString() || "0");
  searchParams.append("pageSize", "20");
  searchParams.append("sortColumn", "name");
  searchParams.append("reverseSort", "0");

  const results = await queryClient.fetchQuery({
    queryKey: ["plants", activeOrgId, name_search, page],
    queryFn: () =>
      axios.get(`${apiUrl("location/locations")}?${searchParams.toString()}`),
    staleTime: ONE_HOUR_IN_MILLISECONDS,
  });

  const plants = results?.data?.data?.map((plant: PlantLocation) => ({
    label: `${plant.name} (${plant.code})`,
    value: plant,
  }));

  const hasMore =
    results.data.meta.currentPage < results.data.meta.totalPages - 1;

  const nextPage = results.data.meta.currentPage + 1;

  return {
    options: plants,
    hasMore,
    additional: hasMore && nextPage,
  };
}
